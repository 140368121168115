define('js/lib/i18n/jedInstance',[
    'jed'
], function (
    Jed
) {
    'use strict';

    var i18n;

    return {
        set: function (config) {
            i18n = new Jed(config);
        },
        get: function () {
            return i18n;
        }
    };
});
