/* eslint-disable max-lines */
/* eslint-disable max-statements */
define('main',[
    'js/lib/checkRequiredFeatures',
    'js/views/UnsupportedFeaturesView',
    'js/lib/featureFlags',
    'js/lib/getUser',
    'js/lib/sceneRotator',
    'js/config/getConfig',
    'js/config/clientSideConfig',
    'js/lib/communicationErrors',
    'js/views/InfoView',
    'js/views/SceneControlsView',
    'js/views/SharedDeckSlideControlsView',
    'js/views/CountdownMessageOverlayView',
    'js/views/SharedDeckView',
    'js/lib/getConfigErrorMessage',
    'js/lib/getInviteErrorMessage',
    'js/lib/deckIdFromDisplayId',
    'js/lib/invite',
    'js/lib/reloader',
    'js/lib/localeLoader',
    'js/lib/viziaEvents',
    'js/lib/deckEvents',
    'js/lib/notifyOnDeckEvents',
    'js/lib/reloadOnDeckEvents',
    'js/lib/explain',
    'js/lib/queryString',
    'js/lib/loadTheme',
    'js/lib/auth',
    'js/controllers/messages',
    'js/views/WarningView',
    'js/globals/vizia_access_token',
    'js/globals/displayId',
    'js/globals/isPreview',
    'js/lib/touchEvents',
    'js/lib/analytics'
], function (
    checkRequiredFeatures,
    UnsupportedFeaturesView,
    featureFlags,
    getUser,
    sceneRotator,
    getConfig,
    clientSideConfig,
    communicationErrors,
    InfoView,
    SceneControlsView,
    SharedDeckSlideControlsView,
    CountdownMessageOverlayView,
    SharedDeckView,
    getConfigErrorMessage,
    getInviteErrorMessage,
    deckIdFromDisplayId,
    invite,
    reloader,
    localeLoader,
    viziaEvents,
    deckEvents,
    notifyOnDeckEvents,
    reloadOnDeckEvents,
    explain,
    queryString,
    loadTheme,
    auth,
    messages,
    WarningView,
    kioskAuth,
    displayId,
    isPreview,
    touchEvents,
    analytics
) {
    'use strict';

    var MISSING_IDS = new Error('No display ID, deck ID or invite ID given.');

    var unsupportedFeatures = checkRequiredFeatures();
    var query = queryString.parse();
    var deckId = query.deckId;
    var vizia_access_token = kioskAuth.get();
    var vaToken = vizia_access_token || '';
    var jwtRegex = /^[a-zA-Z0-9\-_=]+\.[a-zA-Z0-9\-_=]+\.[a-zA-Z0-9\-_=]+$/;
    var kioskMode = vaToken.length === 36 || vaToken.match(jwtRegex);
    var lastLocationKey = 'last_location';

    // When the URL is an invite to a shared deck this will set properties
    // related to the invite on the invite module.
    invite.setParamsFromUrl(query);

    if (unsupportedFeatures.length > 0) {
        var unsupportedFeaturesView = new UnsupportedFeaturesView({
            unsupportedFeatures: unsupportedFeatures
        });

        unsupportedFeaturesView.render();

        return document.body.appendChild(unsupportedFeaturesView.el);
    }

    function showError(error, type) {
        var message;

        if (error === MISSING_IDS) {
            return redirectToAdmin();
        } else if (type === undefined || type === 'config') {
            message = getConfigErrorMessage.get(error);
        } else if (type === 'invite') {
            message = getInviteErrorMessage.get(error);
        } else {
            message = 'Unexpected ' + type + ' error. Status code: ' + error.code;
        }
        var errorView = new CountdownMessageOverlayView();

        errorView.render(message, 60).then(reloader.reload);

        document.body.appendChild(errorView.el);

        //we may want to filter out 4xx s so we don't blow through rollbar quota with people accessing decks they can't see
        analytics.promise().then(function (client) {
            client.errors.error(message, error);
        });

        errorTelemetry(error);
    }

    function redirectToAdmin() {
        var adminUrl = 'https://admin.' + window.location.hostname;
        window.location.replace(adminUrl);
    }

    function addSceneControls() {
        var controlsParent;
        var controlsView;

        if (invite.isEmail) {
            controlsView = new SharedDeckSlideControlsView();
            controlsParent = document.querySelector('.SharedDeckView');
        } else {
            controlsView = new SceneControlsView();
            controlsParent = document.body;
        }

        controlsView.render();
        controlsParent.appendChild(controlsView.el);
        controlsView.listenTo(document.body);

        controlsView.on('first', sceneRotator.first);
        controlsView.on('previous', sceneRotator.previous);
        controlsView.on('pause', sceneRotator.pause);
        controlsView.on('play', sceneRotator.resume);
        controlsView.on('next', sceneRotator.next);
        controlsView.on('last', sceneRotator.last);

        function listenForPopstate(event) {
            if (!event.state || event.state.goBack) {
                sceneRotator.previous();
            } else {
                sceneRotator.next();
            }
        }

        window.addEventListener('popstate', listenForPopstate);
    }

    function shouldEnableTouch() {
        return featureFlags.promise().then(function (ldclient) {
            return ldclient.safeVariation('bootstrap-enable-touch-support', false);
        });
    }

    function onConfigLoaded(config) {
        if (sessionStorage.getItem('reloadedOnDeckEvent')) {
            config.reloadedOnDeckEvent = true;
            sessionStorage.removeItem('reloadedOnDeckEvent');
        }

        if (config.installationId) {
            var eventsConfig = {
                installationId: config.installationId,
                vizia_access_token: config.options.vizia_access_token || vizia_access_token
            };
            //setupEventsErrorView();
            viziaEvents.init(eventsConfig);
            messages.init(eventsConfig);
        }
        if (config.folder_id && !config.is_public) {
            deckEvents.init({
                deckId: config.id,
                isPreview: isPreview,
                eventsRootUrl: clientSideConfig().eventsRootUrl
            });

            if (invite.isEmail) {
                notifyOnDeckEvents.start(InfoView);
            } else {
                reloadOnDeckEvents.start();
            }
        }
        if (!config.options.locale) {
            config.options.locale = 'en-GB';
        }
        if (!config.options.themeId) {
            config.options.themeId = 'brandwatch-dark';
        }
        if (!config.options.timezone) {
            config.options.timezone = 'Etc/UTC';
        }
        if (!config.options.nsfw) {
            config.options.nsfw = 'moderate';
        }

        explain.listen();

        sceneRotator.loadConfig(config);

        if (!config.layout || config.layout.length === 0) {
            return showError({code: 'No scenes in this display.'});
        }

        document.body.appendChild(sceneRotator.el);

        loadTheme(config);

        if (invite.isEmail) {
            var sharedDeckView = new SharedDeckView({
                deckId: config.id,
                deckName: config.name,
                folderId: config.folder_id
            });
            sharedDeckView.render();
            document.body.appendChild(sharedDeckView.el);

            var visitedDecks = JSON.parse(localStorage.getItem('visitedDecks')) || [];

            if (visitedDecks.indexOf(config.id) === -1) {
                var msg = 'That link you just followed gives you access for an entire year (unless you change browsers or clear cookies).';
                var infoView = new InfoView({
                    message: msg,
                    timeout: 10000
                });
                infoView.render();
                document.body.appendChild(infoView.el);

                visitedDecks.push(config.id);
                localStorage.setItem('visitedDecks', JSON.stringify(visitedDecks));
            }
        }

        if (!config.isPreview && config.layout.length > 1) {
            addSceneControls();
            shouldEnableTouch().then(function (enable) {
                if (enable) {
                    touchEvents.addTouchSupport();
                }
            });
        }

        // The scene rotator publishes slide stats (e.g slide x of y), that
        // some scene controls use; therefore this has to be started after
        // the controls have been added, to ensure the stats aren't published
        // before the controls are listening.
        sceneRotator.start({
            index: query.sceneIndex || 0,
            autoRotate: !invite.isInvite() || !invite.isEmail
        });
    }

    function augmentConfig(config) {
        return featureFlags.promise().then(function (client) {
            return Object.assign({}, config, {
                flags: {
                    useFrontendAnalytics: client.safeVariation('use-frontend-analytics', false),
                    useRollbarInBundles: client.safeVariation('bundle-loader-use-rollbar', false)
                }
            });
        });
    }

    function loadLocale(config) {
        return localeLoader.load(config.options.locale || 'en-GB').then(function () {
            return config;
        });
    }

    function waitForPreviewConfig() {
        window.addEventListener('message', function (evt) {
            var config = evt.data && evt.data.config;

            if (!config) {
                return;
            }

            config.isPreview = true;

            return loadLocale(config).then(onConfigLoaded, showError);
        });

        var parentOrigin = new URL(document.referrer).origin;
        var allowedOrigins = clientSideConfig().adminRootUrl.split(',').map(function (url) {
            return new URL(url).origin;
        });

        if (allowedOrigins.indexOf(parentOrigin) === -1) {
            return;
        }

        parent.postMessage('readyForSceneData', parentOrigin);
    }

    function getAppropriateTokenForUser(user) {
        return function (authObj) {
            if (authObj.usePlatformAuth && !authObj.idToken && !kioskMode) {
                if (invite.isInvite()) {
                    return invite.getFootgunToken().then(function (token) {
                        return {
                            usePlatformAuth: true,
                            idToken: token
                        };
                    });
                } else {
                    return auth.upgradeAuth(user.api2AccessToken);
                }
            }
            return authObj;
        };
    }

    function requestConfig(authObj) {
        if (displayId) {
            if (authObj.usePlatformAuth) {
                return deckIdFromDisplayId.get(displayId, authObj)
                    .then(function (mappingObj) {
                        location.search = 'deckId=' + mappingObj.id + '&fromDisplay=true';
                        // This timer will give it time to redirect
                        // without continuing the promise flow.
                        return new Promise(function (resolve) {
                            setTimeout(resolve, 10000);
                        });
                    })
                    .catch(function (err) {
                        // eslint-disable-next-line no-console
                        console.error(err);
                        // eslint-disable-next-line no-console
                        console.error('displayId redirect failed. Falling back...');
                    })
                    .then(function () {
                        return getConfig({displayId: displayId}, authObj);
                    });
            }
            return getConfig({displayId: displayId}, authObj);
        } else if (deckId) {
            return getConfig({deckId: deckId}, authObj);
        }
        throw MISSING_IDS;
    }

    // cheap way of not crashing if session store isn't there for whatever reason
    function getSessionStore() {
        return window.sessionStorage || {
            setItem: function () {},
            getItem: function () {},
            removeItem: function () {}
        };
    }

    function main (user) {
        //setup telemetry
        if (window.telemetry && window.telemetry.sessionDetails) {
            window.telemetry.sessionDetails({
                clientId: user.clientId,
                userId: user.id,
                size: window.innerWidth + 'x' + window.innerHeight
            });
        }

        // set up our feature flag client
        featureFlags.client(clientSideConfig().launchDarklyClientKey, {key: user.clientId});

        // we have to pass this via the user object as otherwise LD won't be initialized yet.
        var inviteObj = user.inviteObj;

        if (inviteObj && inviteObj.deck_share_type === 'email' && inviteObj.invitee_id) {
            analytics.promise().then(function (client) {
                client.track('Invite View', {
                    deckId: inviteObj.deck_id,
                    inviteId: inviteObj.invite_id,
                    inviteeId: inviteObj.invitee_id,
                    inviteeUserId: inviteObj.invitee_user_id
                });
            }).catch(console.error); // eslint-disable-line no-console
        } else {
            analytics.promise();
        }

        auth.getAuthObject()
            .then(getAppropriateTokenForUser(user))
            .then(requestConfig)
            .then(loadLocale)
            .then(augmentConfig)
            .then(onConfigLoaded)
            .catch(showError);
    }

    function redirectIfReferredFromAuth() {
        return auth.getStore(invite.otp).then(function (store) {
            var referredByLogin = document.referrer.startsWith(store.loginUrl);
            var lastLocation = getSessionStore().getItem(lastLocationKey);

            getSessionStore().removeItem(lastLocationKey);

            // If server headers wipe the path from the referrer, redirect to the
            // stored href that _does_ have the path. This is important, as the path
            // contains the deckId/displayId the user is trying to view.
            if (referredByLogin && lastLocation && lastLocation !== location.href) {
                location.replace(lastLocation);
                return new Promise(function (resolve) {
                    setTimeout(resolve, 10000); // give it time to redirect
                });
            }
        }).catch(function (error) {
            // eslint-disable-next-line no-console
            console.error(new Error('Problem running referrer redirection'));
            // eslint-disable-next-line no-console
            console.error(error);
        });
    }

    function errorTelemetry(error) {
        if (window.telemetry && window.telemetry.sessionDetails) {
            window.telemetry.instance.sessionReady.then(function (session) {
                var root = document.querySelector('.CountdownMessageOverlayView');
                var tid = document.createElement('div');
                tid.className = 'CountdownMessageOverlayView__tid';

                tid.innerText = session;

                root.appendChild(tid);
                window.telemetry.instance.cancelUnload();
            });
            window.telemetry.sessionDetails({
                error: error.message || error.toString()
            });
        }
    }

    function checkRouteIsValid() {
        if (!displayId && !deckId && !invite.isInvite()) {
            throw MISSING_IDS;
        }
    }

    if (isPreview) {
        return waitForPreviewConfig();
    }

    if (invite.isInvite()) {
        return redirectIfReferredFromAuth()
            .then(checkRouteIsValid)
            .then(function () {
                return invite.getInvite();
            })
            .then(function (inviteObj) {
                if (!inviteObj.deck_id) {
                    throw new Error('No deck_id given');
                }

                deckId = inviteObj.deck_id;

                return getUser.getPromise().then(function (user) {
                    return Object.assign({}, user, {inviteObj: inviteObj});
                });
            })
            .then(main)
            .catch(function (err) {
                showError(err, 'invite');
            });
    }

    var publicRegex = /\/([p])\/(\w+)/;
    var isPublic = location.pathname.match(publicRegex);

    if (isPublic) {
        featureFlags.client(clientSideConfig().launchDarklyClientKey, {key: ''});

        deckId = isPublic ? isPublic[2] : undefined;

        return getConfig({deckId: deckId})
            .then(loadLocale)
            .then(onConfigLoaded)
            .catch(showError);
    }

    // If the user is logged in execute the main entrypoint
    // If not, log in, reload the page & execute the main entrypoint

    return redirectIfReferredFromAuth()
        .then(checkRouteIsValid)
        .then(function () {
            return getUser.getPromise();
        })
        .then(main, function (err) {
            if (err === MISSING_IDS) {
                return showError(MISSING_IDS);
            }
            if (err.code !== 401) {
                return showError({code: err.message});
            }
            // the referer is wiped by our server headers.
            // This remembers what content it was trying to view
            // when the user is redirected back here
            getSessionStore().setItem(lastLocationKey, location.href);

            auth.getStore().then(function (store) {
                location.replace(store.loginUrl);
            });
        });
});
