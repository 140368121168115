define('js/lib/tileFlipper',[
    'js/lib/windowMessaging',
    'js/config/clientSideConfig'
], function (
    windowMessaging,
    clientSideConfig
) {
    'use strict';

    var appConfig = clientSideConfig();

    var durations = {
        initialDelay: 5 * 1000,
        showFront: 10 * 1000,
        showBack: 8 * 1000
    };

    var state = {
        flipTimeout: null,
        bundleListeners: null,
        flipping: false,
        flipIds: []
    };

    var flippableTiles = [
        'country_volume_change',
        'category_breakdown',
        'history',
        'share_of_voice',
        'top_authors',
        'top_categories',
        'top_sites',
        'topics',
        'total_mentions'
    ];

    function isFlippable(tile) {
        return flippableTiles.indexOf(tile.scene.type) !== -1;
    }

    function registerBundle(iframeEl, tile) {
        if (state.flipIds.indexOf(iframeEl.id) !== -1 || !isFlippable(tile)) {
            return;
        }
        state.flipIds.push(iframeEl.id);
        startFlipping(durations.initialDelay);
    }

    function unregisterBundle(iframeEl) {
        state.flipIds.splice(state.flipIds.indexOf(iframeEl.id, 1));
    }

    function postMessage(id, message) {
        var iframe = document.getElementById(id);
        if (iframe) {
            iframe.contentWindow.postMessage(message, appConfig.bundleLoaderRootUrl);
        }
        return id;
    }

    function newQueue(lastQueue) {
        var seed = state.flipIds.slice();
        var lastFlipped = lastQueue && lastQueue[0];

        var q = state.flipIds.map(function () {
            return seed.splice(Math.floor(Math.random() * seed.length), 1)[0];
        });

        return q[0] === lastFlipped ? q.slice(1).concat(lastFlipped) : q;
    }

    function nextQueue(queue) {
        return queue && queue.length >= 2 ? queue.slice(1) : newQueue(queue);
    }

    function flip(queue) {
        var id = queue[0];
        postMessage(id, {tileCommand: 'flip'});
        setTimeout(function () {
            postMessage(id, {tileCommand: 'unflip'});
        }, durations.showBack);
        scheduleFlip(queue);
    }

    function scheduleFlip(queue) {
        var next = nextQueue(queue);
        postMessage(next[0], {tileCommand: 'prepareFlip'});
        state.flipTimeout = setTimeout(function () {
            flip(next);
        }, durations.showFront);
    }

    function startFlipping(delay) {
        if (!state.flipping) {
            state.flipping = true;
            state.flipTimeout = setTimeout(scheduleFlip, delay || 0);
        }
    }

    function stopListening() {
        if (state.bundleListeners) {
            state.bundleListeners.forEach(function (ref) {
                windowMessaging.events.off(ref);
            });
        }
        state.bundleListeners = null;
    }

    function stopFlipping() {
        clearTimeout(state.flipTimeout);
        state.flipping = false;
        stopListening();
    }

    function register(scene) {
        if (!scene || !scene.options || !scene.options.tileFlipping || scene.options.tileMagnification) {
            return;
        }
        stopListening();
        state.bundleListeners = [
            windowMessaging.events.on('windowMessaging:registerBundle', registerBundle),
            windowMessaging.events.on('windowMessaging:unregisterBundle', unregisterBundle)
        ];
    }

    return {
        init: register,
        start: startFlipping,
        stop: stopFlipping
    };
});
