define('js/lib/injectCSS',[],function () {
    'use strict';

    return function loadCSS(path) {
        var link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = path;

        document.head.appendChild(link);
    };
});
