define('js/lib/TileMagnifier/transformToTile',[],function () {
    'use strict';

    function getElementCenter(boundingRect) {
        return {
            x: Math.round(boundingRect.left + boundingRect.width / 2),
            y: Math.round(boundingRect.top + boundingRect.height / 2)
        };
    }

    function getTileDirectionRelativeToScene(sceneRect, tileRect) {
        var origin = [];

        if (sceneRect.top === tileRect.top) {
            origin.push('top');
        } else if (sceneRect.bottom === tileRect.bottom) {
            origin.push('bottom');
        }

        if (sceneRect.right === tileRect.right) {
            origin.push('right');
        } else if (sceneRect.left === tileRect.left) {
            origin.push('left');
        }

        return origin.join('-');
    }

    function transformToTile(sceneRect, tileRect) {
        var sceneCenter = getElementCenter(sceneRect);
        var tileCenter = getElementCenter(tileRect);
        var origin = getTileDirectionRelativeToScene(sceneRect, tileRect);

        var transform = {
            originClass: origin,
            translate: {
                x: 0,
                y: 0
            }
        };

        if ((origin === 'top' || origin === 'bottom') && sceneCenter.x !== tileCenter.x) {
            transform.translate.x = sceneCenter.x - tileCenter.x;
        } else if ((origin === 'right' || origin === 'left') && sceneCenter.y !== tileCenter.y) {
            transform.translate.y = sceneCenter.y - tileCenter.y;
        } else if (origin === '') {
            transform.translate.x = sceneCenter.x - tileCenter.x;
            transform.translate.y = sceneCenter.y - tileCenter.y;
        }

        return transform;
    }

    return {toTile: transformToTile};
});
