define('js/lib/createElement',[],function () {
    'use strict';

    return function createElement(tagName, className, attrsHash) {
        var el = document.createElement(tagName);
        el.className = className || '';

        if (attrsHash) {
            Object.keys(attrsHash).forEach(function (key) {
                el[key] = attrsHash[key];
            });
        }

        return el;
    };
});
