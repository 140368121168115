define('js/lib/localeLoader',[
    'js/lib/i18n/jedInstance',
    'moment'
], function (
    jedInstance,
    moment
) {
    'use strict';

    var momentLocales = {
        'en-GB': {
            name: 'en-gb',
            path: 'moment/en-gb'
        },
        'it-IT': {
            name: 'it',
            path: 'moment/it'
        },
        'fr-FR': {
            name: 'fr',
            path: 'moment/fr'
        },
        'de-DE': {
            name: 'de',
            path: 'moment/de'
        },
        'es-ES': {
            name: 'es',
            path: 'moment/es'
        },
        'pt-BR': {
            name: 'pt-br',
            path: 'moment/pt-br'
        }
    };

    var validLocales = Object.keys(momentLocales).concat('en-US');

    function loadLocale(locale) {
        if (validLocales.indexOf(locale) === -1) {
            return Promise.resolve();
        }

        var momentLocale = momentLocales[locale];
        var requires = ['json!i18n/' + locale + '.json'];

        if (momentLocale) {
            requires.push(momentLocale.path);
        }

        return new Promise(function (resolve, reject) {
            require(requires, function (strings) {
                jedInstance.set(strings);

                if (momentLocale) {
                    moment.locale(momentLocale.name);
                }

                resolve();
            }, function () {
                reject(new Error('Problem loading locale "' + locale + '"'));
            });
        });
    }

    return {load: loadLocale};
});
