define('js/config/getConfig',[
    'js/lib/communicationErrors',
    'js/config/clientSideConfig',
    'js/globals/vizia_access_token'
], function (
    communicationErrors,
    clientSideConfig,
    kioskAuth
) {
    'use strict';

    var config = clientSideConfig();

    function handleResponse(request, resolve, reject) {
        if (request.status < 200 || request.status > 299) {
            return reject(communicationErrors.createUnexpectedStatusError(request.status));
        }

        try {
            resolve(JSON.parse(request.responseText));
        } catch (e) {
            reject(communicationErrors.createUnparseableResponseError());
        }
    }

    function getConfig(objectIndentifier, authObj) {
        var request = new XMLHttpRequest();
        request.timeout = config.requestTimeout * 1000;
        request.withCredentials = !!authObj;

        return new Promise(function (resolve, reject) {
            if (!objectIndentifier.displayId && !objectIndentifier.deckId) {
                return reject(new Error('No display ID or deck ID in URL.'));
            }

            request.onload = function () {
                handleResponse(request, resolve, reject);
            };

            request.onerror = function () {
                var label = objectIndentifier.deckId ? 'deck' : 'display';
                var value = objectIndentifier.deckId || objectIndentifier.displayId;

                reject(communicationErrors.createCustomError(
                    'Failed to get config for ' + label + ' \'' + value + '\'. Please check admin to see if it exists.'
                ));
            };

            request.ontimeout = function () {
                reject(communicationErrors.createTimeoutError());
            };

            request.open('GET', config.tychoRootUrl + 'v1/config/decks/' + objectIndentifier.deckId);

            var jwtRegex = /^[a-zA-Z0-9\-_=]+\.[a-zA-Z0-9\-_=]+\.[a-zA-Z0-9\-_=]+$/;
            var vizia_access_token = kioskAuth.get();

            // Kiosk Mode: set auth explicitly for legacy and non-legacy.
            if (vizia_access_token) {
                if (vizia_access_token.match(jwtRegex)) {
                    request.setRequestHeader('Authorization', 'Bearer ' + vizia_access_token);
                } else {
                    request.setRequestHeader('vizia_access_token', vizia_access_token);
                }
            // Normal Mode: set auth explicitly for non-legacy auth (legacy uses already set cookie)
            } else if (authObj && authObj.usePlatformAuth) {
                request.setRequestHeader('Authorization', 'Bearer ' + authObj.idToken);
            }

            request.send();
        });
    }

    return getConfig;
});
