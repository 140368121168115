define('js/lib/mixpanel',[
    'vendor/mixpanel',
    'js/config/clientSideConfig',
    'js/lib/getUser'
], function (
    mixpanel,
    getConfig,
    getUser
) {
    'use strict';

    return {
        init: function() {
            getUser.getPromise().then(function (user) {
                var config = getConfig();
                mixpanel.init(config.mixpanelToken);
                mixpanel.identify(user.id);
                mixpanel.register({
                    clientId: user.clientId,
                    app: 'bootstrap'
                });
            }, function () {
                //not signed in yet, no worries
            });
        },

        get: function() {
            return mixpanel;
        }
    };
});
