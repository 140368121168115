define('js/views/UnsupportedFeaturesView',[
    'js/lib/checkRequiredOptions'
], function (
    checkRequiredOptions
) {
    'use strict';

    var requiredOptions = ['unsupportedFeatures'];

    function createElement(tagName, className) {
        var el = document.createElement(tagName);
        el.className = className || '';
        return el;
    }

    function UnsupportedFeaturesView(options) {
        checkRequiredOptions('UnsupportedFeaturesView', requiredOptions, options);
        this.unsupportedFeatures = options.unsupportedFeatures;
        this.el = createElement('div', 'UnsupportedFeaturesView');
    }

    UnsupportedFeaturesView.prototype.render = function render() {
        var headerEl = createElement('h1', 'vc-text-error');
        headerEl.textContent = '⚠ Unable to load Vizia';

        var subHeaderEl = createElement('h3');
        subHeaderEl.textContent = 'This browser doesn\'t support the following required features:';

        var listEl = createElement('ul');

        this.unsupportedFeatures.forEach(function (featureName) {
            var listItemEl = createElement('li');
            listItemEl.textContent = featureName;
            listEl.appendChild(listItemEl);
        });

        this.el.appendChild(headerEl);
        this.el.appendChild(subHeaderEl);
        this.el.appendChild(listEl);
    };

    UnsupportedFeaturesView.prototype.remove = function remove() {
        this.el.remove();
    };

    return UnsupportedFeaturesView;
});
