define('js/views/InfoView',[
    'EventEmitter',
    'js/lib/inherits'
], function (
    EventEmitter,
    inherits
) {
    'use strict';

    var parser = new DOMParser();

    function getSvg(str) {
        return parser.parseFromString(str, 'image/svg+xml').documentElement;
    }

    /* eslint-disable max-len */

    var infoSvg = getSvg('\
        <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">\
            <g stroke="none" stroke-width="1" fill-rule="evenodd">\
                <g>\
                    <path d="M10.5,14 L5.5,14 L5.5,12.75 L6.75,12.75 L6.75,7 L5.5,7 L5.5,5.75 L9.25,5.75 L9.25,12.75 L10.5,12.75 L10.5,14 Z M9.375,3 C9.375,3.759 8.75777778,4.375 8,4.375 C7.24222222,4.375 6.625,3.759 6.625,3 C6.625,2.24222222 7.24222222,1.625 8,1.625 C8.75777778,1.625 9.375,2.24222222 9.375,3 Z" id="Information"></path>\
                </g>\
            </g>\
        </svg>\
    ');

    var crossSvg = getSvg('\
        <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">\
            <g stroke-width="1" fill-rule="evenodd">\
                <g fill-rule="nonzero" stroke-width="2.1">\
                    <path d="M3.75,12.25 L12.25,3.75" id="Path" class="ax-animicon__path-1"></path>\
                    <path d="M3.75,3.75 L12.25,12.25" id="Path" class="ax-animicon__path-2"></path>\
                </g>\
            </g>\
        </svg>\
    ');

    /* eslint-enable max-len */

    function InfoView(options) {
        EventEmitter.call(this);

        this.el = document.createElement('div');
        this.el.className = 'InfoView';

        this.msg = options.message;

        this.onClick = this.onClick.bind(this);
        this.el.addEventListener('click', this.onClick);

        if (options.timeout) {
            this.removeTimer = setTimeout(this.remove.bind(this), options.timeout);
        }
    }

    InfoView.prototype.onClick = function (evt) {
        var target = evt.target;
        var isCross = false;
        var isSvg = false;

        while (target !== this.el) {
            if (target.tagName.toLowerCase() === 'svg') {
                isSvg = true;
            }

            if (target.classList.contains('cross')) {
                isCross = true;
                break;
            }

            target = target.parentElement;
        }

        if (isSvg && isCross) {
            this.remove();
        }
    };

    InfoView.prototype.render = function render() {
        var infoDiv = document.createElement('div');
        infoDiv.className = 'info';
        infoDiv.appendChild(infoSvg);

        var msgDiv = document.createElement('div');
        msgDiv.className = 'msg';
        msgDiv.textContent = this.msg;

        var crossDiv = document.createElement('div');
        crossDiv.className = 'cross';
        crossDiv.appendChild(crossSvg);

        this.el.appendChild(infoDiv);
        this.el.appendChild(msgDiv);
        this.el.appendChild(crossDiv);
    };

    InfoView.prototype.remove = function remove() {
        var self = this;

        clearTimeout(this.removeTimer);

        this.el.classList.add('fade');

        this.trigger('remove');

        this.el.addEventListener('transitionend', function onTransitionEnd() {
            self.el.remove();
            self.el.removeEventListener('click', self.onClick);
            self.el.removeEventListener('transitionend', onTransitionEnd);
        });
    };

    inherits(InfoView, EventEmitter);

    return InfoView;
});
