define('js/lib/windowMessaging',[
    'js/config/clientSideConfig',
    'EventEmitter',
    'js/lib/analytics'
], function (
    clientSideConfig,
    EventEmitter,
    analytics
) {
    'use strict';

    var tiles = new WeakMap();
    var tileConfigs = new WeakMap();
    var appConfig = clientSideConfig();
    var bus = new EventEmitter();

    function onMessage(event) {
        var tile = tiles.get(event.source);

        if (!tile) {
            return;
        }

        var bundleLoaderOrigin = appConfig.bundleLoaderRootUrl.replace(/\/$/, '');

        if (event.origin !== bundleLoaderOrigin) {
            return;
        }

        var tileConfig = tileConfigs.get(event.source);

        function postToTile(message) {
            if (!event.source || !event.source.postMessage) {
                analytics.promise().then(function (client) {
                    client.errors.info('No postMessage function available', {
                        event: event,
                        message: message
                    });
                });
                return;
            }
            event.source.postMessage(message, bundleLoaderOrigin);
        }
        bus.trigger(event.data.type, event.data.data, tile, postToTile, tileConfig);
    }

    function registerBundle(iframeEl, tile, tileConfig) {
        var tileWindow = iframeEl.contentWindow;
        if (!tiles.has(tileWindow)) {
            tiles.set(tileWindow, tile);
            tileConfigs.set(tileWindow, tileConfig);
            bus.trigger('windowMessaging:registerBundle', iframeEl, tile, tileConfig);
        }
    }

    function unregisterBundle(iframeEl) {
        bus.trigger('windowMessaging:unregisterBundle', iframeEl);
        tiles.delete(iframeEl.contentWindow);
        tileConfigs.delete(iframeEl.contentWindow);
    }

    window.addEventListener('message', onMessage);

    return {
        register: registerBundle,
        unregister: unregisterBundle,
        events: bus
    };
});
