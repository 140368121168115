define('js/lib/featureFlags',[
    'ldclient-js'
], function (
    ldclient
) {
    'use strict';

    var instance;
    var readyPromise;

    function buildClient(key, user, options) {
        instance = ldclient.initialize(key, user, options);

        instance.safeVariation = function (key, fallback) {
            try {
                return instance.variation(key, fallback);
            } catch (e) {
                return fallback;
            }
        };

        readyPromise = new Promise(function (resolve) {
            instance.on('ready', function () {
                resolve(instance);
            });
        });

        instance.on('change', function () {});
    }

    return {
        client: function (key, user, options) {
            if (!instance) {
                buildClient(key || 'placeholder', user, options || {});
            } else if (user) {
                instance.identify(user);
            }
            return instance;
        },
        reset: function () {
            instance = null;
            readyPromise = null;
        },
        promise: function () {
            return readyPromise || Promise.reject(new Error('Launch Darkly not initialised'));
        }
    };
});
