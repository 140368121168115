define('js/lib/TileMagnifier/steps/EmphasizeStep',[
    'js/lib/TileMagnifier/MagnificationStep'
], function (
    MagnificationStep
) {
    'use strict';

    return MagnificationStep.define({
        transitionProperty: 'opacity',
        start: function emphasize(component) {
            component.trigger('magnifyInEmphasize');
            component.el.classList.add('magnify-target');
            this.sceneEl.classList.add('fade');
        }
    });
});
