define('js/lib/viziaEvents',[
    'EventEmitter',
    'js/config/clientSideConfig',
    'js/lib/reloader',
    'js/lib/retryer',
    'js/globals/displayId',
    'js/globals/isPreview'
], function (
    EventEmitter,
    clientSideConfig,
    reloader,
    retryer,
    displayId,
    isPreview
) {
    'use strict';

    var emitter = new EventEmitter();

    emitter.init = function (opts) {
        if (isPreview) {
            return;
        }

        var eventsUrl = [
            clientSideConfig().eventsRootUrl,
            'v1/stream/installation/',
            opts.installationId,
            '?vizia_access_token=',
            encodeURIComponent(opts.vizia_access_token)
        ].join('');

        retryer(function (retry, reset) {
            var eventSource = new self.EventSource(eventsUrl, {withCredentials: true});
            var interval;

            eventSource.onerror = function () {
                if (interval) {
                    clearInterval(interval);
                }

                eventSource.close();
                eventSource = null;
                emitter.trigger('viziaEvents:error');
                retry();
            };

            eventSource.onopen = function () {
                reset();
                emitter.trigger('viziaEvents:open');

                var lastTime = Date.now();

                // When chrome has an open EventSource connection and the
                // machine goes to sleep, the connection will no longer receive
                // events and no error will be thrown. This attempts to detect
                // such an occurrence and replace the connection when it
                // happens.
                // https://bugs.chromium.org/p/chromium/issues/detail?id=225654#
                interval = setInterval(function () {
                    var currentTime = Date.now();

                    if (currentTime > lastTime + 2000) {
                        eventSource.close();
                        clearInterval(interval);
                        emitter.init(opts);
                    }

                    lastTime = currentTime;
                }, 1000);
            };

            eventSource.addEventListener('refresh', reloader.forceReload);

            eventSource.addEventListener('message', function (evt) {
                var messageObject = JSON.parse(evt.data);

                emitter.trigger(messageObject.channel, messageObject.message);
            });
        });
    };

    emitter.on('display:refresh', function (message) {
        if (message.displayId === displayId) {
            reloader.reload();
        }
    });

    emitter.on('display:delete', function (message) {
        if (message.displayId === displayId) {
            reloader.reload();
        }
    });

    emitter.on('installation:refresh', function () {
        reloader.reload();
    });

    emitter.on('installation:delete', function () {
        reloader.reload();
    });

    return emitter;
});
