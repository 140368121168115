define('js/lib/TileMagnifier/MagnificationStep',[
    'js/lib/checkRequiredOptions',
    'js/lib/animationEndHelpers',
    'js/lib/noop'
], function (
    checkRequiredOptions,
    animationEndHelpers,
    noop
) {
    'use strict';

    var requiredOptions = ['sceneEl'];

    function listenToTransitionOnce(step, component, transitionDone) {
        function onTransition(event) {
            if (event && step.transitionProperty && event.propertyName !== step.transitionProperty) {
                return;
            }
            step.cleanup();
            step.end(component);
            transitionDone();
        }

        step.listenerCleanupFn = animationEndHelpers.removeEndEventListeners
            .bind(null, step.sceneEl, onTransition);

        animationEndHelpers.addEndEventListeners(step.sceneEl, onTransition);

        step.timeout = setTimeout(onTransition, step.timeLimit);
    }

    function MagnificationStep(options) {
        checkRequiredOptions('MagnificationStep', requiredOptions, options);
        this.sceneEl = options.sceneEl;
    }

    MagnificationStep.prototype.timeLimit = 1000;
    MagnificationStep.prototype.start = noop;
    MagnificationStep.prototype.end = noop;

    MagnificationStep.prototype.animate = function (component, done) {
        listenToTransitionOnce(this, component, done);
        this.start(component);
    };

    MagnificationStep.prototype.cleanup = function () {
        if (this.listenerCleanupFn) {
            this.listenerCleanupFn();
        }
        clearTimeout(this.timeout);
    };

    MagnificationStep.define = function defineMagnificationStep(stepDefinition) {
        function Step() {
            MagnificationStep.apply(this, arguments);
        }

        Step.prototype = Object.keys(stepDefinition).reduce(function (proto, definitionKey) {
            proto[definitionKey] = stepDefinition[definitionKey];
            return proto;
        }, Object.create(MagnificationStep.prototype));

        return Step;
    };

    return MagnificationStep;
});
