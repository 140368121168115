define('js/lib/TileMagnifier/steps/MagnifyOutStep',[
    'js/lib/TileMagnifier/MagnificationStep'
], function (
    MagnificationStep
) {
    'use strict';

    var transformOriginClasses = [
        'top',
        'top-right',
        'right',
        'bottom-right',
        'bottom',
        'bottom-left',
        'left',
        'top-left'
    ];

    return MagnificationStep.define({
        transitionProperty: 'transform',
        start: function magnifyOutStart(component) {
            component.trigger('magnifyOutStart');
            this.sceneEl.style.transform = '';
        },
        end: function magnifyOutEnd(component) {
            this.sceneEl.classList.remove('fade');
            this.sceneEl.classList.remove.apply(this.sceneEl.classList, transformOriginClasses);
            component.el.classList.remove('magnify-target');
            component.trigger('magnifyOutEnd');
        }
    });
});
