define('js/lib/deckIdFromDisplayId',[
    'js/lib/communicationErrors',
    'js/config/clientSideConfig'
], function(
    communicationErrors,
    getClientSideConfig
) {
    'use strict';

    var config = getClientSideConfig();

    function conversionUrl(displayId) {
        var url = new URL(config.tychoRootUrl);
        url.pathname = '/v1/decks';
        url.searchParams.set('old_id', displayId);
        return url.href;
    }

    function getDeckIdFromDisplayId(displayId, authObj) {
        return new Promise(function (resolve, reject) {
            var request = new XMLHttpRequest();

            request.timeout = config.requestTimeout * 1000;
            request.open('GET', conversionUrl(displayId));
            request.setRequestHeader('Authorization', 'Bearer ' + authObj.idToken);

            request.onerror = function () {
                reject(communicationErrors.createRequestError());
            };

            request.ontimeout = function () {
                reject(communicationErrors.createTimeoutError());
            };

            request.onload = function (evt) {
                var data;

                if (evt.target.status < 200 || evt.target.status > 299) {
                    reject(communicationErrors.createUnexpectedStatusError(evt.target.status));
                    return;
                }

                try {
                    data = JSON.parse(evt.target.responseText);
                } catch (err) {
                    reject(err);
                    return;
                }

                resolve(data);
            };

            request.send();
        });
    }

    return {get: getDeckIdFromDisplayId};
});
