define('js/lib/buildSceneUrl',[
    'vendor/wrapple'
], function (
    wrapple
) {
    'use strict';

    return function buildSceneUrl(params) {
        var paramString = Object.keys(params).reduce(function (query, key) {
            query.push([key, encodeURIComponent(params[key])].join('='));
            return query;
        }, []).join('&');
        var location = wrapple.location();
        return location.origin + location.pathname + '?' + paramString;
    };
});
