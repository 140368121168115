define('js/lib/reloader',['vendor/wrapple'], function (wrapple) {
    'use strict';

    wrapple('location');

    return {
        reload: function () {
            //tell telemetry not to end session on page unload
            if (window.telemetry && window.telemetry.instance) {
                window.telemetry.instance.cancelUnload();
            }
            wrapple.location().reload();
        },
        forceReload: function () {
            //tell telemetry not to end session on page unload
            if (window.telemetry && window.telemetry.instance) {
                window.telemetry.instance.cancelUnload();
            }
            wrapple.location().reload(true);
        }
    };
});
