define('js/views/SceneTitleView',[
    'js/lib/checkRequiredOptions',
    'js/lib/createElement'
], function (
    checkRequiredOptions,
    createElement
) {
    'use strict';

    var requiredOptions = [
        'title'
    ];

    function SceneTitleView(options) {
        checkRequiredOptions('SceneTitleView', requiredOptions, options);
        this.title = options.title;
        this.el = createElement('div', 'sceneHeader__title');
    }

    SceneTitleView.prototype.render = function render() {
        this.el.textContent = this.title;
    };

    SceneTitleView.prototype.remove = function remove() {
        this.el.remove();
    };

    return SceneTitleView;
});
