define('js/views/WarningView',[],function () {
    'use strict';

    function WarningView() {
        this.el = document.createElement('div');
        this.el.className = 'WarningView vl-text-large vl-padding-small';

        this.iconEl = document.createElement('span');
        this.iconEl.innerHTML = '!';
        this.el.appendChild(this.iconEl);
    }

    WarningView.prototype.render = function (message) {
        this.el.title = message;
    };

    WarningView.prototype.remove = function () {
        this.el.remove();
    };

    return WarningView;
});
