/**
 * Module that facilitates accessing Vizia via invite links.
 *
 * > Determines if an invite link is in use and extracts relevant data from
 *   the URL (i.e. the one-time password, aka OTP, and the invite ID).
 * > Uses the OTP to get a footgun token that is used to get the invite object
 *   from Tycho.
 */
define('js/lib/invite',[
    'js/lib/auth',
    'js/config/clientSideConfig',
    'js/lib/communicationErrors',
    'vendor/wrapple'
], function(
    auth,
    getClientSideConfig,
    communicationErrors,
    wrapple
) {
    'use strict';

    var config = getClientSideConfig();

    // Invites to shared decks are namespaced URLs:
    // /i for email invites.
    // /s for signage link invites.
    // The invite ID is the part of the URL immediately after the namespace.
    var inviteRegex = /\/([is])\/(\w+)/;

    var invite = {
        // These properties are populated by check() when it determines that the
        // URL is an invite. The properties are exposed on the module so that
        // they can be used elsewhere in bootstrap.
        inviteId: null,
        otp: null,

        isEmail: false,
        isSignage: false,

        // User data that comes from the footgun token.
        // Populated by getFootgunToken().
        user: {
            clientId: null,
            id: null
        },

        // Determines whether the current URL is an invite to a shared deck.
        // If so, inviteId and otp are populated on this object.
        setParamsFromUrl: function(query) {
            var location = wrapple.location();

            var inviteMatches = location.pathname.match(inviteRegex);

            invite.inviteId = inviteMatches ? inviteMatches[2] : undefined;
            invite.otp = query.otp;

            invite.isEmail = !!inviteMatches && inviteMatches[1] === 'i';
            invite.isSignage = !!inviteMatches && inviteMatches[1] === 's';
        },

        isInvite: function() {
            return !!invite.inviteId;
        },

        // Gets the invite object from Tycho. This requires check() to have
        // been run first, since that sets inviteId and otp.
        getInvite: function() {
            if (!invite.inviteId) {
                return Promise.reject(new Error('No invite ID'));
            }

            if (!invite.otp) {
                return Promise.reject(new Error('No OTP'));
            }

            // Get a footgun token and use it to get the invite from tycho.
            return invite.getFootgunToken().then(function (token) {
                if (!token) {
                    throw new Error('No token');
                }

                return new Promise(function (resolve, reject) {
                    var data;

                    var request = new XMLHttpRequest();

                    // Request the invite. This endpoint is a POST because it writes
                    // stats in relation to the invite's usage.
                    request.timeout = config.requestTimeout * 1000;
                    request.open('POST', config.tychoRootUrl + 'v1/invites/' + invite.inviteId + '/view');
                    request.setRequestHeader('Authorization', 'Bearer ' + token);

                    request.onerror = function () {
                        reject(communicationErrors.createRequestError());
                    };

                    request.ontimeout = function () {
                        reject(communicationErrors.createTimeoutError());
                    };

                    request.onload = function (evt) {
                        if (evt.target.status < 200 || evt.target.status > 299) {
                            reject(communicationErrors.createUnexpectedStatusError(evt.target.status));
                            return;
                        }

                        try {
                            data = JSON.parse(evt.target.responseText);
                        } catch (err) {
                            reject(err);
                            return;
                        }

                        resolve(data);
                    };

                    request.send();
                });
            });
        },

        // Uses the OTP to get a footgun token and also populates user data.
        getFootgunToken: function() {
            return auth.getStore(invite.otp).then(function getFootgunToken(store) {
                return store.getToken({aud: 'vizia.brandwatch.com/foot-gun'});
            }).then(function (token) {
                if (!token) {
                    return null;
                }

                // Parse JWT.
                var payload = JSON.parse(atob(token.split('.')[1]));

                invite.user.clientId = payload.clientId;
                invite.user.id = payload.sub;

                return token;
            });
        }
    };

    return invite;
});
