define('js/views/SceneView',[
    'js/lib/checkRequiredOptions',
    'js/lib/createElement',
    'js/views/SceneHeaderView',
    'js/views/SceneLayoutView',
    'js/lib/tileFlipper'
], function (
    checkRequiredOptions,
    createElement,
    SceneHeaderView,
    SceneLayoutView,
    tileFlipper
) {
    'use strict';

    var requiredOptions = [
        'colors',
        'title',
        'logoSrc',
        'timezone',
        'layout',
        'locale',
        'themeId',
        'timezone'
    ];

    function formatTelemetryId(str) {
        return str.replace(/-/g, '–');
    }

    function SceneView(options) {
        checkRequiredOptions('SceneView', requiredOptions, options);
        this.options = options;

        this.headerView = new SceneHeaderView(options);
        this.layoutView = new SceneLayoutView(options);

        this.el = createElement('div', 'SceneView');
        this.headerViewEl = createElement('div', 'SceneView__header');
        this.bodyViewEl = createElement('div', 'SceneView__body');
        this.footerViewEl = createElement('div', 'SceneView__footer');
        this.telemetryViewEl = createElement('div', 'SceneView__footer__telemetry');
        this.footerMessageViewEl = createElement('div', 'SceneView__footer__message');

        tileFlipper.init(options.layout);
    }

    SceneView.prototype.render = function render() {
        this.el.innerHTML = '';

        this.layoutView.render();

        if (this.options.showHeader !== false) {
            this.headerView.render();
            this.el.appendChild(this.headerViewEl);
            this.headerViewEl.appendChild(this.headerView.el);

            if (this.options.theme && this.options.theme.usePrimaryHeader) {
                this.headerViewEl.classList.add('SceneView__header--primary');
            }
        }
        this.el.appendChild(this.bodyViewEl);
        this.footerViewEl.appendChild(this.telemetryViewEl);
        this.footerViewEl.appendChild(this.footerMessageViewEl);
        this.el.appendChild(this.footerViewEl);

        if (window.telemetry && window.telemetry.instance) {
            window.telemetry.instance.sessionReady.then(function(session) {
                this.telemetryViewEl.innerText = formatTelemetryId(session);
            }.bind(this));
        }

        this.bodyViewEl.appendChild(this.layoutView.el);

        setTimeout(function() {
            window.onSlideChange && window.onSlideChange();
        }, 1);
    };

    SceneView.prototype.remove = function remove() {
        this.headerView.remove();
        this.layoutView.remove();
        this.el.remove();
        this.headerViewEl.remove();
        this.bodyViewEl.remove();
        this.footerViewEl.remove();
        tileFlipper.stop();
    };

    return SceneView;
});
