define('js/lib/TileMagnifier/animateStepsInSequence',[],function () {
    'use strict';

    function animateStep(steps, stepIndex, tile, done) {
        var thisStep = steps[stepIndex];

        if (!thisStep) {
            return done();
        }

        thisStep.animate(tile, function nextAnimationStep() {
            animateStep(steps, stepIndex + 1, tile, done);
        });
    }

    function animateStepsInSequence(steps, tile) {
        return new Promise(function (resolve) {
            animateStep(steps, 0, tile, resolve);
        });
    }

    return {animateInSequence: animateStepsInSequence};
});
