define('js/lib/screenTracking',[
    'vendor/wrapple',
    'vendor/util-guid',
    'moment'
], function (
    wrapple,
    guid,
    moment
) {
    'use strict';

    wrapple('sessionStorage');

    var id16 = guid.bind(guid, 16);

    function getOrSeedValue(key, generator) {
        var val = wrapple('sessionStorage').getItem(key);
        if (!val) {
            val = generator();
            wrapple('sessionStorage').setItem(key, val);
        }
        return val;
    }

    function getTabId(idGenerator) {
        return getOrSeedValue('vizia_tab_id', idGenerator || id16);
    }

    function getTabBirth() {
        return getOrSeedValue('vizia_tab_birth', moment.now);
    }

    function getUptime () {
        var startDate = moment(parseInt(getTabBirth(), 10));
        var duration = moment.duration(moment(moment.now()).diff(startDate));
        return duration.asMinutes();
    }

    function payload() {
        return {
            tabId: getTabId(),
            tabUptime: getUptime()
        };
    }

    return {
        getId: getTabId,
        getUptime: getUptime,
        payload: payload
    };
});
