define('js/views/PopupMessageView',[
    'js/lib/messageHelpers',
    'js/lib/createElement',
    'js/lib/onceEvent'
], function (
    messageHelpers,
    createElement,
    onceEvent
) {
    'use strict';

    function makePopup(options) {
        var messageContainer = createElement('div', 'message-container ' + options.styleClass);
        var header = createElement('header', 'message-header');
        var content = createElement('div', 'body', {textContent: options.content});

        if (options.iconClass) {
            header.appendChild(createElement('span', 'message-icon ' + options.iconClass));
        }

        header.appendChild(createElement('span', 'title', {textContent: options.title}));

        messageContainer.appendChild(header);
        messageContainer.appendChild(content);

        return messageContainer;
    }

    function PopupMessageView(message) {
        if (!message) {
            throw new Error('PopupMessageView requires a message object.');
        }

        var templateData = messageHelpers.makeTemplateData(message);

        this.el = createElement('div', 'PopupMessageView vc-bg');
        this.el.appendChild(makePopup(templateData));
    }

    function animateOut(view) {
        view.el.classList.add('animate-out');

        return onceEvent(view.el, 'animationend').then(function () {
            view.el.remove();
        });
    }

    PopupMessageView.prototype.animate = function () {
        var view = this;

        view.el.classList.add('animate-in');

        return onceEvent(view.el, 'animationend').then(function () {
            view.el.classList.remove('animate-in');
        });
    };

    PopupMessageView.prototype.remove = function () {
        var view = this;

        if (!view.el.classList.contains('animate-in')) {
            return animateOut(view);
        }

        return onceEvent(view.el, 'animationend').then(function () {
            return animateOut(view);
        });
    };

    return PopupMessageView;
});
