define('js/lib/explain',[
    'js/lib/windowMessaging',
    'js/lib/TileMagnifier/TileMagnifier',
    'js/lib/ExplanationManager'
], function (
    windowMessaging,
    TileMagnifier,
    ExplanationManager
) {
    'use strict';

    var magnifiers = new WeakMap();
    var queues = new WeakMap();

    function getMagnifier(el) {
        if (!el) {
            return;
        }
        return magnifiers.get(el) || getMagnifier(el.parentElement);
    }

    function explain(peaks, tile, postToTile, config) {
        var magnifier = getMagnifier(tile.el);

        if (!magnifier) {
            return;
        }
        if (magnifier.sceneEl.offsetWidth === tile.el.offsetWidth) {
            return;
        }
        if (magnifier.sceneEl.offsetHeight === tile.el.offsetHeight) {
            return;
        }

        var queue = queues.get(magnifier) || queues.set(magnifier, []).get(magnifier);

        queue.push(Promise.all(queue).then(function () {
            if (!getMagnifier(tile.el)) {
                return; // layout was unregistered between message receipt and queue completion
            }

            ExplanationManager.manage({
                points: peaks.points,
                explanations: peaks.explanations,
                tile: tile,
                postToTile: postToTile,
                config: config
            });

            return magnifier.magnifyTile(tile);
        }));
    }

    function register(sceneEl, options) {
        magnifiers.set(sceneEl, new TileMagnifier({
            sceneEl: sceneEl,
            timeBetweenMagnifications: options.timeBetweenMagnifications,
            magnificationHoldTime: options.magnificationHoldTime
        }));
    }

    function unregister(sceneEl) {
        var magnifier = magnifiers.get(sceneEl);

        if (!magnifier) {
            return;
        }

        magnifier.stop();
        magnifiers.delete(sceneEl);
    }

    function listen() {
        return windowMessaging.events.on('explain:peaks', explain);
    }

    return {
        register: register,
        unregister: unregister,
        listen: listen
    };
});
