define('js/lib/TileMagnifier/getScaleFactor',[],function () {
    'use strict';

    function getScaleFactor(sceneRect, tileRect) {
        if (tileRect.height === sceneRect.height || tileRect.width === sceneRect.width) {
            return 1;
        }

        var tileHeightsInSceneHeight = sceneRect.height / tileRect.height;
        var tileWidthsInSceneWidth = sceneRect.width / tileRect.width;

        return Math.min(tileWidthsInSceneWidth, tileHeightsInSceneHeight);
    }

    return {get: getScaleFactor};
});
