define('js/lib/writeUrl',[
    'js/globals/vizia_access_token',
    'js/lib/analytics',
    'js/lib/buildSceneUrl',
    'js/lib/featureFlags',
    'js/lib/invite',
    'js/lib/screenTracking',
    'js/lib/queryString',
    'vendor/wrapple'
], function(
    kioskAuth,
    analytics,
    buildSceneUrl,
    featureFlags,
    invite,
    screenTracking,
    queryString,
    wrapple
) {
    'use strict';

    function makeParams(config, nextSceneIndex) {
        var params = {
            sceneIndex: nextSceneIndex
        };

        if (kioskAuth.get()) {
            params.kiosk = true;
        }

        if (queryString.parse().showHeader === 'false') {
            params.showHeader = 'false';
        }

        if (config.is_public) {
            return params;
        }

        if (invite.isInvite()) {
            // Preserve the invite URL so that bootstrap continues to use the
            // invite auth flow.
            params.otp = invite.otp;
        } else if (config.folder_id) {
            params.deckId = config.id;
        } else {
            params.displayId = config.id;
        }

        return params;
    }

    function shouldRefreshBetweenScenes(nextSceneIndex) {
        // Never refresh when the deck was shared via an email, as this is a
        // different use case to a long running signage setup.
        if (invite.isEmail) {
            return Promise.resolve(false);
        }

        // Always refresh when showing the first slide of the deck.
        if (nextSceneIndex === 0) {
            return Promise.resolve(true);
        }

        // Only refresh for other slides if this Launch Darkly flag has been set.
        return featureFlags.promise().then(function (ldclient) {
            return ldclient.safeVariation('bootstrap-refresh-between-every-scene', false);
        });
    }

    return function writeUrl(config, currentIndex, nextSceneIndex) {
        var params = makeParams(config, nextSceneIndex);
        var url = buildSceneUrl(params);

        if (currentIndex === null || currentIndex === undefined) {
            history.replaceState({}, null, url);
            return Promise.resolve(false);
        } else {
            return shouldRefreshBetweenScenes(nextSceneIndex).then(function (shouldRefresh) {
                if (shouldRefresh) {
                    // Log that a "rotation" has been completed; in most cases
                    // this means each slide in the deck has been shown in
                    // turn (the exceptions are when the deck was launched from
                    // a slide other than the first, or when refreshing between
                    // every slide).
                    analytics.promise().then(function (client) {
                        client.track('rotation end', screenTracking.payload());
                    }, function () {});

                    //tell telemetry not to end session on page unload
                    if (window.telemetry && window.telemetry.instance) {
                        window.telemetry.instance.cancelUnload();
                    }

                    // Reload bootstrap, but starting at the next slide.
                    wrapple.location().href = url;
                } else {
                    // Don't reload, just update the URL.
                    history.pushState({
                        goBack: currentIndex < nextSceneIndex
                    }, null, url);
                }

                return Promise.resolve(shouldRefresh);
            });
        }
    };
});
