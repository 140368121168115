define('js/views/ClockView',[
    'js/lib/timezones',
    'js/lib/datetimeFormatter',
    'vendor/moment-timezone',
    'js/lib/checkRequiredOptions'
], function (
    timezoneData,
    datetimeFormatter,
    moment,
    checkRequiredOptions
) {
    'use strict';

    moment.tz.load(timezoneData);

    function domNode() {
        var node = document.createElement('div');
        node.className = 'sceneHeader__clock';
        return node;
    }

    function ClockView(options) {
        checkRequiredOptions('ClockView', ['timezone', 'locale'], options);
        this.timezone = options.timezone;
        this.locale = options.locale;
        this.el = domNode();
    }

    ClockView.prototype.render = function render() {
        var view = this;
        var lastUpdate = 0;

        function loop(delta) {
            view.timer = requestAnimationFrame(loop);
            if (delta - lastUpdate > 1000) {
                view.el.textContent = datetimeFormatter.formatForClock(view.locale, view.timezone);
                lastUpdate = delta;
            }
        }
        view.timer = requestAnimationFrame(loop);

        this.el.textContent = datetimeFormatter.formatForClock(this.locale, this.timezone);
    };

    ClockView.prototype.remove = function remove() {
        cancelAnimationFrame(this.timer);
        this.el.remove();
    };

    return ClockView;
});
