define('js/lib/auth',[
    'js/config/clientSideConfig',
    'js/globals/vizia_access_token',
    'js/lib/featureFlags',
    'donny-auth'
], function (
    clientSideConfig,
    kioskAuth,
    featureFlags,
    TokenStore
) {
    'use strict';

    var USAGE_DEFAULT = true;
    var store;

    function shouldUsePlatformAuth() {
        return featureFlags.promise().then(function (ldclient) {
            return ldclient.safeVariation('use-platform-auth', USAGE_DEFAULT);
        });
    }

    function getStore(otp) {
        var config = clientSideConfig();
        if (!store) {
            if (otp) {
                store = TokenStore.fromOTP(config.authStoreDomain, otp);
            } else {
                store = Promise.resolve(new TokenStore(config.authStoreDomain));
            }
        }
        return store;
    }

    function resolveAfter(value, wait) {
        return new Promise(function (resolve) {
            setTimeout(function () {
                resolve(value);
            }, wait);
        });
    }

    function getAuthObject() {
        var token = kioskAuth.getJwt();

        if (token) {
            return Promise.resolve({usePlatformAuth: true, idToken: token});
        }

        return getStore()
            .then(function (store) {
                return store.getToken({aud: 'vizia.brandwatch.com/foot-gun', secondsValidFor: 60 * 30});
            })
            .then(function (token) {
                if (token !== null) {
                    return {usePlatformAuth: true, idToken: token};
                }

                var shouldUse = Promise.race([
                    shouldUsePlatformAuth(),
                    // ensures that if launch darkly is being slow, we still get a resolution
                    resolveAfter(USAGE_DEFAULT, 1500)
                ]);

                return shouldUse.then(function (usePlatformAuth) {
                    return { usePlatformAuth: usePlatformAuth };
                });
            });
    }

    function upgradeAuth(api2token) {
        return getStore()
            .then(function (store) {
                return store.upgradeToken({
                    aud: 'vizia.brandwatch.com/foot-gun',
                    secondsValidFor: 60 * 30,
                    api2_token: api2token
                });
            })
            .then(function (token) {
                if (token !== null) {
                    return {usePlatformAuth: true, idToken: token};
                }
                return location.replace(getStore().loginUrl);
            });
    }

    return {
        getStore: getStore,
        getAuthObject: getAuthObject,
        upgradeAuth: upgradeAuth,
        reset: function () {
            store = null;
        }
    };
});
