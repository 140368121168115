define('js/views/SceneLayoutView',[
    'js/lib/checkRequiredOptions',
    'js/lib/createElement',
    'js/lib/explain',
    'js/views/TileView'
], function (
    checkRequiredOptions,
    createElement,
    explain,
    TileView
) {
    'use strict';

    function domNode(isFlippedHorizontally, isFlippedVertically) {
        var horizontalFlipClass = isFlippedHorizontally ? 'SceneLayoutView--flipped-horizontally' : '';
        var verticalFlipClass = isFlippedVertically ? 'SceneLayoutView--flipped-vertically' : '';

        return createElement('div', [
            'SceneLayoutView',
            'vl-flex-column',
            'vl-flex-1',
            horizontalFlipClass,
            verticalFlipClass
        ].join(' '));
    }

    function rowNode(rowData) {
        var flexGrow = rowData.height || 1;
        return createElement('div', 'vl-flex-row vl-flex-' + flexGrow);
    }

    function columnNode(columnData) {
        var flexGrow = columnData.width || 1;
        return createElement('div', 'vl-flex-column vl-flex-' + flexGrow);
    }

    function wrapperNode() {
        return createElement('div', 'layout-wrapper vl-flex-column vl-flex-wrap vl-flex-1');
    }

    function buildLayout(node, layoutData, opts) {
        if (!layoutData.rows) {
            return;
        }

        var tileViews = [];

        layoutData.rows.forEach(function buildRow(rowData) {
            var rowWrapper = rowNode(rowData);
            node.appendChild(rowWrapper);

            if (!rowData.columns) {
                return;
            }
            rowData.columns.forEach(function buildColumn(columnData, index) {
                var columnWrapper = columnNode(columnData);
                rowWrapper.appendChild(columnWrapper);

                if (columnData.rows) {
                    tileViews = tileViews.concat(buildLayout(columnWrapper, columnData, opts));
                    return;
                }

                if (columnData.scene) {
                    var scene = Object.assign({}, columnData.scene);

                    if (scene.component_type_id) {
                        scene.type = scene.component_type_id;
                    }

                    var tileView = new TileView({
                        index: index,
                        scene: scene,
                        colors: opts.colors,
                        locale: opts.locale,
                        themeId: opts.themeId,
                        theme: opts.theme,
                        timezone: opts.timezone,
                        token: opts.token,
                        installationId: opts.installationId,
                        nsfw: opts.nsfw,
                        vizia_access_token: opts.vizia_access_token,
                        bwApiRootUrl: opts.bwApiRootUrl,
                        environment: opts.environment,
                        imagecheckRootUrl: opts.imagecheckRootUrl,
                        flags: opts.flags,
                        is_public: opts.is_public,
                        reloadedOnDeckEvent: opts.reloadedOnDeckEvent
                    });
                    tileView.render();
                    columnWrapper.appendChild(tileView.el);
                    tileViews.push(tileView);
                }
            });
        });

        return tileViews;
    }

    function SceneLayoutView(options) {
        checkRequiredOptions('SceneLayoutView', [
            'colors',
            'layout',
            'locale',
            'timezone'
        ], options);

        this.layout = options.layout;

        this.el = domNode(
            this.layout.options.layout_is_flipped_horizontally,
            this.layout.options.layout_is_flipped_vertically
        );

        this.tileConfig = {
            colors: options.colors,
            locale: options.locale,
            themeId: options.themeId,
            theme: options.theme,
            timezone: options.timezone,
            token: options.token,
            installationId: options.installationId,
            nsfw: options.nsfw,
            vizia_access_token: options.vizia_access_token,
            bwApiRootUrl: options.bwApiRootUrl,
            environment: options.environment,
            imagecheckRootUrl: options.imagecheckRootUrl,
            flags: options.flags,
            is_public: options.is_public,
            reloadedOnDeckEvent: options.reloadedOnDeckEvent
        };
    }

    SceneLayoutView.prototype.render = function render() {
        var wrapper = wrapperNode();
        this.el.innerHTML = '';
        this.el.appendChild(wrapper);

        if (this.layout.options.tileMagnification) {
            explain.register(this.el, {
                timeBetweenMagnifications: this.layout.options.timeBetweenMagnifications * 1000,
                magnificationHoldTime: this.layout.options.magnificationHoldTime * 1000
            });
        }

        this.tileViews = buildLayout(wrapper, this.layout, this.tileConfig);
    };

    SceneLayoutView.prototype.remove = function remove() {
        if (this.tileViews) {
            this.tileViews.forEach(function (tileView) {
                tileView.remove();
            });
        }
        explain.unregister(this.el);
        this.el.remove();
    };

    return SceneLayoutView;
});
