define('js/lib/TileMagnifier/steps/MagnifyInStep',[
    'js/lib/getRoundedClientRect',
    'js/lib/TileMagnifier/MagnificationStep',
    'js/lib/TileMagnifier/getScaleFactor',
    'js/lib/TileMagnifier/transformToTile'
], function (
    roundedClientRect,
    MagnificationStep,
    scaleFactor,
    transform
) {
    'use strict';

    function buildTransformationString(scaleFactor, transform) {
        return [
            'scale(' + scaleFactor + ')',
            'translate(' + transform.translate.x + 'px, ' + transform.translate.y + 'px)'
        ].join(' ');
    }

    return MagnificationStep.define({
        transitionProperty: 'transform',
        start: function magnifyInStart(component) {
            var sceneRect = roundedClientRect.get(this.sceneEl);
            var tileRect = roundedClientRect.get(component.el);
            var transformation = transform.toTile(sceneRect, tileRect);

            this.scaleFactor = scaleFactor.get(sceneRect, tileRect);

            component.trigger('magnifyInStart');

            if (transformation.originClass) {
                this.sceneEl.classList.add(transformation.originClass);
            }

            this.sceneEl.style.transform = buildTransformationString(this.scaleFactor, transformation);
        },
        end: function magnifyInEnd(component) {
            component.trigger('magnifyInEnd', this.scaleFactor);
        }
    });
});
