/**
 * These are the controls for navigating through a deck's slides for a deck
 * that has been shared (e.g. via an email).
 */
define('js/views/SharedDeckSlideControlsView',[
    'EventEmitter',
    'js/lib/inherits',
    'js/lib/analytics',
    'js/lib/createElement',
    'js/lib/sceneRotator'
], function (
    EventEmitter,
    inherits,
    analytics,
    createElement,
    sceneRotator
) {
    'use strict';

    var parser = new DOMParser();

    function getSvg(str) {
        return parser.parseFromString(str, 'image/svg+xml').documentElement;
    }

    /* eslint-disable max-len */

    var doubleChevronLeft = getSvg('\
        <svg viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">\
            <g id="Page-1" stroke="none" stroke-width="1" fill-rule="evenodd">\
                <g id="double-chevron-left" fill-rule="nonzero">\
                    <path d="M8,4.5 L4.5,8 L8,11.5 L6.5,13 L1.5,8 L6.5,3 L8,4.5 Z M13,4.5 L9.5,8 L13,11.5 L11.5,13 L6.5,8 L11.5,3 L13,4.5 Z"></path>\
                </g>\
            </g>\
        </svg>\
    ');

    var chevronLeft = getSvg('\
        <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">\
            <g id="Page-1" stroke="none" stroke-width="1" fill-rule="evenodd">\
                <g id="chevron-left" fill-rule="nonzero">\
                    <polygon id="Chevron-Left" points="11 4 9.5 2.5 4 8 9.5 13.5 11 12 7 8"></polygon>\
                </g>\
            </g>\
        </svg>\
    ');

    var chevronRight = getSvg('\
        <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">\
            <g id="Page-1" stroke="none" stroke-width="1" fill-rule="evenodd">\
                <g id="chevron-right" fill-rule="nonzero">\
                    <polygon id="Chevron-Right" points="5 12 6.5 13.5 12 8 6.5 2.5 5 4 9 8"></polygon>\
                </g>\
            </g>\
        </svg>\
    ');

    var doubleChevronRight = getSvg('\
        <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">\
            <g id="Page-1" stroke="none" stroke-width="1" fill-rule="evenodd">\
                <g id="double-chevron-right" fill-rule="nonzero">\
                    <path d="M3,11.5 L6.5,8 L3,4.5 L4.5,3 L9.5,8 L4.5,13 L3,11.5 Z M8,11.5 L11.5,8 L8,4.5 L9.5,3 L14.5,8 L9.5,13 L8,11.5 Z" id="Double-Chevron-Right"></path>\
                </g>\
            </g>\
        </svg>\
    ');

    /* eslint-enable max-len */

    var instance;
    var unbindEventFns = new WeakMap();

    var LEFT_ARROW = 37;
    var RIGHT_ARROW = 39;

    function track(event) {
        analytics.promise().then(function (client) {
            client.track(event);
        }, function() {});
    }

    function onButtonPress(viewEl, event) {
        var buttonEl;
        var buttonClassName = 'button';
        var target = event.target;

        while (target !== viewEl) {
            if (target.classList.contains(buttonClassName)) {
                buttonEl = target;
                break;
            }

            target = target.parentElement;
        }

        if (!buttonEl) {
            return;
        }

        if (buttonEl.classList.contains('first')) {
            track('first slide');
            return instance.trigger('first');
        }

        if (buttonEl.classList.contains('previous')) {
            track('previous slide');
            return instance.trigger('previous');
        }

        if (buttonEl.classList.contains('next')) {
            track('next slide');
            return instance.trigger('next');
        }

        if (buttonEl.classList.contains('last')) {
            track('last slide');
            return instance.trigger('last');
        }
    }

    function handleKeyPress(keyCode, shiftKey) {
        if (keyCode === LEFT_ARROW) {
            if (shiftKey) {
                instance.trigger('first');
            } else {
                instance.trigger('previous');
            }
        } else if (keyCode === RIGHT_ARROW) {
            if (shiftKey) {
                instance.trigger('last');
            } else {
                instance.trigger('next');
            }
        }
    }

    function SharedDeckSlideControlsView() {
        instance = this;

        EventEmitter.call(this);

        this.el = createElement('div', 'SharedDeckSlideControlsView');
        this.el.addEventListener('click', onButtonPress.bind(this, this.el));
        unbindEventFns.set(this, new Set());

        sceneRotator.on('slideStats', function onSlideStats(stats) {
            instance.slideIndex = stats.slideIndex;
            instance.totalSlides = stats.totalSlides;

            instance.render();
        });
    }

    inherits(SharedDeckSlideControlsView, EventEmitter);

    SharedDeckSlideControlsView.prototype.render = function render() {
        var disabledClass = 'disabled';
        var firstDisabled = this.slideIndex === 0 ? disabledClass : '';
        var lastDisabled = this.slideIndex === this.totalSlides - 1 ? disabledClass : '';

        this.el.innerHTML = '\
            <div data-viz-at="button-first" class="button first ' + firstDisabled + '" title="First Slide (Shift + Left arrow)"></div>\
            <div data-viz-at="button-previous" class="button previous" title="Previous Slide (Left arrow)"></div>\
            <div>' + (this.slideIndex + 1) + '/' + this.totalSlides + '</div>\
            <div data-viz-at="button-next" class="button next" title="Next Slide (Right arrow)"></div>\
            <div data-viz-at="button-last" class="button last ' + lastDisabled + '" title="Last Slide (Shift + Right arrow)"></div>\
        ';

        this.el.querySelector('.first').appendChild(doubleChevronLeft);
        this.el.querySelector('.previous').appendChild(chevronLeft);
        this.el.querySelector('.next').appendChild(chevronRight);
        this.el.querySelector('.last').appendChild(doubleChevronRight);
    };

    SharedDeckSlideControlsView.prototype.listenTo = function listenTo(targetEl) {
        function onKeyUp(event) {
            handleKeyPress(event.keyCode, event.shiftKey);
        }

        targetEl.addEventListener('keyup', onKeyUp);

        unbindEventFns.get(this).add(function () {
            targetEl.removeEventListener('keyup', onKeyUp);
        });
    };

    SharedDeckSlideControlsView.prototype.remove = function remove() {
        this.el.removeEventListener('click', onButtonPress);

        unbindEventFns.get(this).forEach(function (unbindEventListener) {
            unbindEventListener();
        });

        this.el.remove();
    };

    return SharedDeckSlideControlsView;
});
