define('js/lib/deckEvents',[
    'EventEmitter',
    'js/lib/auth',
    'js/lib/reloader',
    'js/lib/retryer'
], function (
    EventEmitter,
    auth,
    reloader,
    retryer
) {
    'use strict';

    var emitter = new EventEmitter();

    var eventsToListenFor = emitter.eventsToListenFor = (function () {
        var objects = ['folder', 'deck', 'slide', 'component'];
        var actions = ['insert', 'update', 'delete'];

        return objects.reduce(function (events, object) {
            return events.concat(actions.map(function (action) {
                return object + ':' + action;
            }));
        }, []);
    }());

    function eventsAuthRequest(eventsRootUrl, token) {
        var authUrl = [eventsRootUrl, 'v2/auth'].join('');

        return new Promise(function (resolve, reject) {
            var request = new XMLHttpRequest();

            request.onload = function () {
                if (request.status === 204) {
                    resolve();
                } else {
                    reject(new Error('non-204 response from ' + authUrl));
                }
            };

            request.onerror = reject;
            request.withCredentials = true;
            request.open('POST', authUrl);
            request.setRequestHeader('Authorization', 'Bearer ' + token);
            request.send();
        });
    }

    function authWithEvents(eventsRootUrl) {
        return auth.getAuthObject().then(function (authObj) {
            if (!authObj || !authObj.idToken) {
                throw new Error('Unable to retrieve idToken');
            }
            return eventsAuthRequest(eventsRootUrl, authObj.idToken);
        });
    }

    emitter.init = function (opts) {
        if (opts.isPreview) {
            return;
        }

        var eventsUrl = [
            opts.eventsRootUrl,
            'v2/stream/deck/',
            opts.deckId
        ].join('');

        retryer.wrap(function (retry, reset) {
            authWithEvents(opts.eventsRootUrl).then(function () {
                var eventSource = new window.EventSource(eventsUrl, {
                    withCredentials: true
                });
                var interval;

                eventSource.onerror = function (event) {
                    if (interval) {
                        clearInterval(interval);
                    }
                    eventSource.close();
                    eventSource = null;
                    emitter.trigger('error', event);
                    retry();
                };

                eventSource.onopen = function () {
                    reset();
                    emitter.trigger('open');

                    var lastTime = Date.now();

                    // When chrome has an open EventSource connection and the
                    // machine goes to sleep, the connection will no longer receive
                    // events and no error will be thrown. This attempts to detect
                    // such an occurrence and replace the connection when it
                    // happens.
                    // https://bugs.chromium.org/p/chromium/issues/detail?id=225654#
                    interval = setInterval(function () {
                        var currentTime = Date.now();

                        if (currentTime > lastTime + 2000) {
                            eventSource.close();
                            clearInterval(interval);
                            emitter.init(opts);
                        }

                        lastTime = currentTime;
                    }, 1000);
                };

                eventSource.addEventListener('refresh', reloader.forceReload);

                eventsToListenFor.forEach(function (eventName) {
                    eventSource.addEventListener(eventName, function (event) {
                        try {
                            var data = JSON.parse(event.data);
                            emitter.trigger(eventName, data);
                        } catch (err) {
                            emitter.trigger('error', err);
                        }
                    });
                });
            }).catch(function (err) {
                emitter.trigger('error', err);
                retry();
            });
        });
    };

    return emitter;
});
