/**
 * Notifies a user about changes to the deck they're viewing, showing them a
 * prompt to refresh (rather than forcing a reload, which would be jarring for
 * this user experience).
 */
define('js/lib/notifyOnDeckEvents',[
    'js/lib/deckEvents'
], function (
    deckEvents
) {
    'use strict';

    var infoView;
    var listeners;

    return {
        start: function (InfoView) {
            listeners = deckEvents.eventsToListenFor.map(function (eventName) {
                return deckEvents.on(eventName, function () {
                    if (infoView) {
                        return;
                    }

                    infoView = new InfoView({
                        message: 'The content in this Deck has changed. Please refresh the browser to see the changes.'
                    });
                    infoView.render();
                    document.body.appendChild(infoView.el);

                    infoView.on('remove', function () {
                        infoView = null;
                        return Promise.resolve();
                    });
                });
            });
        },
        stop: function () {
            listeners.forEach(function (listener) {
                deckEvents.off(listener);
            });
        }
    };
});
