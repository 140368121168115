define('js/views/SceneLogoView',[
    'js/lib/checkRequiredOptions',
    'js/lib/createElement'
], function (
    checkRequiredOptions,
    createElement
) {
    'use strict';

    var requiredOptions = [
        'logoSrc'
    ];

    function SceneLogoView(options) {
        checkRequiredOptions('SceneLogoView', requiredOptions, options);
        var classNames = ['sceneHeader__logo'];

        if (options.isLegacyTheme) {
            classNames.push('sceneHeader__logo--legacy');
        }

        this.logoSrc = options.logoSrc;
        this.el = createElement('div', classNames.join(' '));
    }

    SceneLogoView.prototype.render = function render() {
        this.el.innerHTML = '';

        var imageEl = createElement('img', 'logo', {src: this.logoSrc});

        this.el.appendChild(imageEl);
    };

    SceneLogoView.prototype.remove = function remove() {
        this.el.remove();
    };

    return SceneLogoView;
});
