define('js/globals/vizia_access_token',['js/lib/queryString'], function (queryString) {
    'use strict';

    var jwtRegex = /^[a-zA-Z0-9\-_=]+\.[a-zA-Z0-9\-_=]+\.[a-zA-Z0-9\-_=]+$/;

    function jwtHasExpired(token) {
        try {
            var payload = JSON.parse(atob(token.split('.')[1]));
            return new Date(payload.exp * 1000) < new Date();
        } catch (e) {
            console.error(e); // eslint-disable-line no-console
            return false;
        }
    }

    function stripTokenFromUrl(oldSearch) {
        var newSearch = '?' + Object.keys(oldSearch).reduce(function (search, key) {
            if (key !== 'vizia_access_token') {
                return search.concat(key + '=' + encodeURIComponent(oldSearch[key]));
            }
            return search;
        }, []).join('&');

        if (!history || !history.replaceState) {
            location.search = newSearch;
        }
        history.replaceState({}, null, newSearch);
    }

    function getToken(searchParams) {
        if (searchParams.otp) {
            //otp should supercede kiosk
            return null;
        }
        var searchToken = searchParams.vizia_access_token;

        if (!searchToken) {
            var storedToken = window.sessionStorage && sessionStorage.getItem('vizia_access_token');

            if (storedToken && storedToken.match(jwtRegex) && jwtHasExpired(storedToken)) {
                sessionStorage.removeItem('vizia_access_token');
                return null;
            }
            return storedToken;
        }

        var decodedToken = decodeURIComponent(searchToken);

        if (decodedToken && decodedToken.match(jwtRegex) && jwtHasExpired(decodedToken)) {
            stripTokenFromUrl(searchParams);
            return null;
        }

        if (window.sessionStorage) {
            sessionStorage.setItem('vizia_access_token', decodedToken);
            stripTokenFromUrl(searchParams);
        }

        return decodedToken;
    }

    return {
        // Returns the stored token regardless of what type
        get: function getKioskToken() {
            return getToken(queryString.parse());
        },

        // Only returns the token if it is a JWT
        getJwt: function getKioskJwt() {
            var token = getToken(queryString.parse());

            if (token && token.match(jwtRegex)) {
                return token;
            }
        }
    };
});
