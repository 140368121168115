define('js/lib/datetimeFormatter',[
    'moment'
], function (
    moment
) {
    'use strict';

    var shortDate = {
        'de-DE': 'D MMM',
        'en-GB': 'D MMM',
        'en-US': 'MMM D',
        'es-ES': 'D MMM',
        'fr-FR': 'D MMM',
        'pt-BR': 'D MMM',
        'it-IT': 'D MMM'
    };

    function formatForClock(locale, timezone) {
        return moment.tz(Date.now(), timezone).format('HH:mm z');
    }

    function timezoneShortDate(datetime, timezone, locale) {
        return moment.tz(datetime, timezone).format(shortDate[locale]);
    }

    return {
        formatForClock: formatForClock,
        timezoneShortDate: timezoneShortDate
    };
});
