define('js/lib/analytics',[
    'Rollbar',
    'buildtimeConfig',
    'js/lib/featureFlags',
    'js/lib/mixpanel',
    'js/lib/getUser',
    'vendor/wrapple'
], function (
    Rollbar,
    buildtimeConfig,
    flags,
    mixpanel,
    getUser,
    wrapple
) {
    'use strict';
    wrapple('location');

    var promise;
    var tokenStripper = /(vizia_access_token=)[^&]*/;

    function noop() {}

    function getRollbarConfig() {
        return getUser.getPromise().then(function (user) {
            return {
                accessToken: window.CONFIG.rollbarToken.value,
                captureUncaught: true,
                scrubFields: ['vizia_access_token', 'idToken'],
                scrubTelemetryInputs: true,
                payload: {
                    environment: window.CONFIG.env.value,
                    javascript: {
                        source_map_enabled: true,
                        guess_uncaught_frames: true,
                        code_version: buildtimeConfig.version || 'development'
                    },
                    person: {
                        id: user.id
                    },
                    clientId: user.clientId
                }
            };
        });
    }

    function init() {
        return Promise.all([flags.promise(), getRollbarConfig()]).then(function (results) {
            var flagClient = results[0];
            if (!flagClient.safeVariation('use-frontend-analytics', false)) {
                return {};
            }

            var mixpanelClient;
            var rollbarClient;

            if (flagClient.safeVariation('bootstrap-use-rollbar', false)) {
                rollbarClient = Rollbar.init(results[1]);
            }

            if (flagClient.safeVariation('bootstrap-use-mixpanel', false)) {
                mixpanel.init();
                mixpanelClient = mixpanel.get();
            }

            return {
                rollbar: rollbarClient,
                mixpanel: mixpanelClient
            };
        });
    }

    function reportRollbarError(client, level) {
        if (!client) {
            return noop;
        }
        return function (msg, error) {
            return client[level](msg || '', error);
        };
    }

    function reportMixpanelEvent(client) {
        if (!client) {
            return noop;
        }
        return function(event, payload) {
            return client.track(event, Object.assign({}, payload, {
                $current_url: wrapple('location').href.replace(tokenStripper, '$1REDACTED')
            }));
        };
    }

    return {
        promise: function () {
            if (promise) {
                return promise;
            }
            promise = init().then(function (clients) {
                return {
                    errors: {
                        debug: reportRollbarError(clients.rollbar, 'debug'),
                        info: reportRollbarError(clients.rollbar, 'info'),
                        warn: reportRollbarError(clients.rollbar, 'warn'),
                        error: reportRollbarError(clients.rollbar, 'error')
                    },
                    track: reportMixpanelEvent(clients.mixpanel)
                };
            });
            return promise;
        }
    };
});
