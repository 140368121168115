define('js/lib/sceneRotator',[
    'EventEmitter',
    'js/config/clientSideConfig',
    'js/config/healthCheck',
    'js/lib/checkRequiredOptions',
    'js/lib/createElement',
    'js/lib/invite',
    'js/views/SceneView',
    'js/globals/vizia_access_token',
    'vendor/wrapple',
    'js/lib/writeUrl',
    'js/lib/analytics',
    'js/lib/screenTracking',
    'js/lib/queryString'
], function (
    EventEmitter,
    clientSideConfig,
    healthCheck,
    checkRequiredOptions,
    createElement,
    invite,
    SceneView,
    kioskAuth,
    wrapple,
    writeUrl,
    analytics,
    screenTracking,
    queryString
) {
    'use strict';

    /* eslint max-statements: off */

    wrapple('location');
    wrapple('document');

    function Deferred() {
        var self = this;

        this.promise = new Promise(function (resolve, reject) {
            self.resolve = resolve;
            self.reject = reject;
        });
    }

    var clientConfig = clientSideConfig();
    var emitter = new EventEmitter();
    var kioskMode;
    var vizia_access_token;
    var rotationPaused;
    var timerPausedAt;
    var sceneRenderedAt;
    var currentIndex;
    var config;
    var options;
    var timer;
    var view;
    var domNode = createElement('div', 'app');
    var rotationDeferred = new Deferred();
    var query = queryString.parse();

    function setKioskModeCookie(opts) {
        var url = new URL(wrapple.location().href);
        var domain = '.' + url.hostname;
        wrapple.document().cookie = 'viziaauthtoken=' + opts.viziaauthtoken + '; domain=' + domain;
    }

    function getTimeout(index) {
        var timePaused = timerPausedAt ? timerPausedAt - sceneRenderedAt : 0;
        return config.layout[index].showFor * 1000 - timePaused;
    }

    // When the deck has multiple slides, schedule the next slide to be after
    // the current slide. If there's only 1 slide, schedule it for 1 hour from
    // now; this will cause the page to be refreshed then.
    function scheduleSceneRotation() {
        if (config.layout.length > 1) {
            timer = setTimeout(rotateScene, getTimeout(currentIndex));
        } else {
            timer = setTimeout(rotateScene, 1000 * 60 * 60);
        }
    }

    function rotateScene() {
        rotationDeferred = new Deferred();

        healthCheck().then(function () {
            start({
                index: currentIndex + 1
            });
            emitter.trigger('sceneRotated');
        })
            .catch(function () {
                console.warn(new Date().toISOString() + ': Not rotating due to failed health check.'); // eslint-disable-line no-console
                scheduleSceneRotation();
            });
    }

    function getSceneOptions(sceneIndex) {
        var layout = config.layout[sceneIndex];
        var logoSrc;

        if (config.options.theme && config.options.theme.logoSrc) {
            logoSrc = config.options.theme.logoSrc;
        } else {
            logoSrc = clientConfig.themesRootUrl + config.options.themeId + '/img/logo.next.svg';
        }

        var options = {
            colors: config.colors,
            layout: layout,
            title: layout && layout.title,
            installationId: config.installationId,
            locale: config.options.locale,
            themeId: config.options.themeId,
            theme: config.options.theme,
            timezone: config.options.timezone,
            showHeader: query.showHeader !== 'false' && (layout && layout.options && layout.options.showHeader) !== false,
            token: config.options.bw_access_token,
            logoSrc: logoSrc,
            nsfw: config.options.nsfw,
            // TODO: `|| vizia_access_token` can be removed once the commit adding
            // it to config-server's blob and this commit have been deployed.
            vizia_access_token: config.options.vizia_access_token || vizia_access_token,
            bwApiRootUrl: config.bwApiRootUrl,
            environment: config.environment,
            imagecheckRootUrl: config.imagecheckRootUrl,
            flags: config.flags,
            is_public: config.is_public,
            reloadedOnDeckEvent: config.reloadedOnDeckEvent
        };

        if (!invite.isInvite()) {
            return Promise.resolve(options);
        }

        // When using an invite URL, set the footgun token as the token to use
        // (which will ultimately be used in bundle-loader's URL).
        return invite.getFootgunToken().then(function (token) {
            options.vizia_access_token = token;
            return options;
        });
    }

    function wrapIndex(index) {
        var len = config.layout.length;
        var remainder = index % len;
        return remainder > -1 ? remainder : len + remainder;
    }

    function loadConfig(options) {
        checkRequiredOptions('sceneRotator', ['options', 'layout'], options);
        config = options;
    }

    function renderCurrentScene() {
        return getSceneOptions(currentIndex).then(function (sceneOptions) {
            if (view) {
                view.remove();
            }

            view = new SceneView(sceneOptions);

            view.render();

            domNode.innerHTML = '';
            domNode.appendChild(view.el);
            sceneRenderedAt = Date.now();
        });
    }

    function start(opts) {
        if (!config) {
            throw new Error('cannot start before loading config');
        }

        options = Object.assign({
            autoRotate: true,
            index: 0
        }, options, opts);

        options.index = options.index || 0;

        vizia_access_token = kioskAuth.get();
        kioskMode = !!vizia_access_token;

        if (kioskMode && !config.folder_id) {
            setKioskModeCookie({
                viziaauthtoken: vizia_access_token
            });
        }
        clearTimeout(timer);

        var isFirstStart = currentIndex === undefined;
        var wrappedIndex = wrapIndex(options.index);
        var sceneHasChanged = currentIndex !== wrappedIndex;

        if (config.layout.length > 1 && !sceneHasChanged) {
            return;
        }

        // Log that a "rotation" through the deck's slides has begun when
        // bootstrap is loaded (unless the deck was shared via an email or it is a public deck).
        if (isFirstStart && !invite.isEmail && !config.is_public) {
            analytics.promise().then(function (client) {
                client.track('rotation start', screenTracking.payload());
            });
        }

        // Update the URL or refresh the page (whichever is appropriate).
        return writeUrl(config, currentIndex, wrappedIndex).then(function (isReloading) {
            // If the page is about to reload then stop and wait for that to
            // happen, rather than starting to needlessly render etc.
            if (isReloading) {
                return Promise.resolve();
            }

            // Render the next slide.
            currentIndex = wrappedIndex;
            var renderPromise = renderCurrentScene();

            // A slide rotation is complete once the slide has been rendered.
            // The unit tests need this to time things correctly.
            renderPromise.then(rotationDeferred.resolve);

            // Emit data for use by other views, e.g. slide controls.
            emitter.trigger('slideStats', {
                slideIndex: currentIndex,
                totalSlides: config.layout.length
            });

            // Schedule when to show the next slide, when automatic rotation is
            // in effect.
            if (options.autoRotate && !rotationPaused) {
                scheduleSceneRotation();
            }

            return renderPromise;
        });
    }

    function first() {
        return start({
            index: 0
        });
    }

    function last() {
        return start({
            index: config.layout.length - 1
        });
    }

    function next() {
        timerPausedAt = false;
        rotateScene();
    }

    function previous() {
        healthCheck().then(function () {
            timerPausedAt = false;
            start({
                index: currentIndex - 1
            });
        })
            .catch(function () {
                console.warn(new Date().toISOString() + ': Not going back due to failed health check.'); // eslint-disable-line no-console
                scheduleSceneRotation();
            });
    }

    function pause() {
        rotationPaused = true;
        timerPausedAt = Date.now();
        clearTimeout(timer);
    }

    function resume() {
        rotationPaused = false;
        scheduleSceneRotation();
    }

    function reset() {
        rotationPaused = false;
        timerPausedAt = false;
        clearTimeout(timer);
        currentIndex = undefined;
        options = null;
    }

    return {
        on: emitter.on.bind(emitter),
        el: domNode,
        loadConfig: loadConfig,
        first: first,
        last: last,
        start: start,
        next: next,
        previous: previous,
        pause: pause,
        resume: resume,
        rotationPromise: function() {
            return rotationDeferred.promise;
        },
        reset: reset
    };
});
