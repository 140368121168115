define('js/views/TickerMessageView',[
    'js/lib/messageHelpers',
    'js/lib/createElement',
    'js/lib/onceEvent'
], function (
    messageHelpers,
    createElement,
    onceEvent
) {
    'use strict';

    var PIXELS_PER_SECOND = 120;

    function makeMarquee(options) {
        var messageContainer = createElement('div', 'message-container ' + options.styleClass);
        var marqueeElement = createElement('span', 'message-marquee');

        if (options.iconClass) {
            marqueeElement.appendChild(createElement('span', 'message-icon ' + options.iconClass));
        }

        marqueeElement.appendChild(createElement('span', 'title', {textContent: options.title}));
        marqueeElement.appendChild(document.createTextNode(' '));
        marqueeElement.appendChild(createElement('span', 'message', {textContent: options.content}));

        messageContainer.appendChild(marqueeElement);

        return messageContainer;
    }

    function TickerMessageView(message) {
        if (!message) {
            throw new Error('TickerMessageView requires a message object.');
        }

        var templateData = messageHelpers.makeTemplateData(message);

        this.el = createElement('div', 'TickerMessageView vc-bg');
        this.el.appendChild(makeMarquee(templateData));
    }

    TickerMessageView.prototype.animate = function () {
        var view = this;
        var marqueeEl = view.el.querySelector('.message-marquee');

        marqueeEl.style['animation-duration'] = Math.max(view.el.offsetWidth, marqueeEl.offsetWidth) / PIXELS_PER_SECOND + 's';

        view.el.classList.add('animate-in');

        return onceEvent(view.el, 'animationend').then(function () {
            if (!view.removing) {
                view.el.classList.remove('animate-in');
                marqueeEl.classList.add('running');
            }
        });
    };

    TickerMessageView.prototype.remove = function () {
        var el = this.el;
        var marqueeEl = el.querySelector('.message-marquee');

        return onceEvent(marqueeEl, 'animationiteration').then(function () {
            marqueeEl.classList.remove('running');
            el.classList.add('animate-out');

            return onceEvent(el, 'animationend').then(function () {
                el.remove();
            });
        });
    };

    return TickerMessageView;
});
