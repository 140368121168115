define('js/lib/ExplanationManager',[
    'js/lib/createElement',
    'js/lib/checkRequiredOptions',
    'js/config/clientSideConfig'
], function (
    createElement,
    checkRequiredOptions,
    clientSideConfig
) {
    'use strict';

    var requiredOptions = ['points', 'explanations', 'tile', 'postToTile', 'config'];
    var appConfig = clientSideConfig();
    var timeBetweenPeaks = 5000;

    function onNarrowAnimationEnd(manager, scaleFactor) {
        manager.tileFrame.classList.add('half-width');

        manager.postToTile({
            type: 'explain:magnifyInEnd',
            data: scaleFactor
        });
    }

    function createBundle(sceneType, config) {
        var bundleEl = createElement('iframe', 'bundle-loader-frame explain-frame hidden', {
            width: '50%',
            height: '100%',
            src: appConfig.bundleLoaderRootUrl + '?bundle=' + encodeURIComponent(sceneType)
        });

        function onIframeLoad() {
            bundleEl.contentWindow.postMessage({config: config}, appConfig.bundleLoaderRootUrl);
            bundleEl.removeEventListener('load', onIframeLoad);
        }

        bundleEl.addEventListener('load', onIframeLoad);

        return bundleEl;
    }

    function onSlideIn(manager, currentIndex, nextFrame) {
        if (!nextFrame.parentElement) {
            manager.tile.el.appendChild(nextFrame);
        }
        manager.frames.forEach(function (frame, frameIndex) {
            if (frameIndex !== currentIndex) {
                frame.classList.add('hidden');
            }
        });
    }

    function showExplanation(frame, frameIndex, manager) {
        manager.frames.forEach(function hideOtherFrames(frame, i) {
            if (i !== frameIndex) {
                frame.style.zIndex = 0;
            }
        });

        frame.style.zIndex = 1;
        frame.classList.remove('hidden');

        manager.postToTile({
            type: 'explain:selectPoint',
            data: [manager.points[0], [manager.points[1][frameIndex]], true]
        });

        var nextIndex = frameIndex + 1 === manager.frames.length ? 0 : frameIndex + 1;
        var nextFrame = manager.frames[nextIndex];

        manager.onSlideTimeout = setTimeout(onSlideIn, 1000, manager, frameIndex, nextFrame);
        manager.explanationTimeout = setTimeout(showExplanation, timeBetweenPeaks, nextFrame, nextIndex, manager);
    }

    function ExplanationManager(options) {
        checkRequiredOptions('ExplanationManager', requiredOptions, options);

        this.points = options.points;
        this.explanations = options.explanations;
        this.tile = options.tile;
        this.postToTile = options.postToTile;
        this.config = options.config;

        this.tileFrame = this.tile.el.querySelector('iframe');
        this.frames = [];
    }

    ExplanationManager.prototype.onTile = function (event, handler) {
        var manager = this;

        this.tile.on(event, function () {
            handler.apply(manager, arguments);
        }, 1);
    };

    ExplanationManager.prototype.prepareExplanations = function () {
        this.frames = this.explanations.map(function (explanation, index) {
            var bundleConfig = Object.assign({}, this.config, {scene: explanation});
            var explanationIframeEl = createBundle(explanation.type, bundleConfig);

            if (index === 0) {
                this.tile.el.appendChild(explanationIframeEl);
            }

            return explanationIframeEl;
        }, this);

        this.postToTile({type: 'explain:magnifyInEmphasize'});
    };

    ExplanationManager.prototype.startShowingExplanations = function (scaleFactor) {
        this.onNarrowTimeout = setTimeout(onNarrowAnimationEnd, 1000, this, scaleFactor);
        showExplanation(this.frames[0], 0, this);
    };

    ExplanationManager.prototype.stopExplaining = function () {
        if (this.onNarrowTimeout) {
            clearTimeout(this.onNarrowTimeout);
            this.onNarrowTimeout = undefined;
        }
        if (this.explanationTimeout) {
            clearTimeout(this.explanationTimeout);
            this.explanationTimeout = undefined;
        }
        if (this.onSlideTimeout) {
            clearTimeout(this.onSlideTimeout);
            this.onSlideTimeout = undefined;
        }
        this.postToTile({type: 'explain:magnifyOutStart'});
    };

    ExplanationManager.prototype.removeExplanations = function () {
        this.tileFrame.classList.remove('half-width');

        var frame = this.frames.shift();

        while (frame) {
            frame.remove();
            frame = this.frames.shift();
        }

        this.postToTile({type: 'explain:magnifyOutEnd'});
    };

    ExplanationManager.manage = function manageExplanations(options) {
        var manager = new ExplanationManager(options);

        manager.onTile('magnifyInEmphasize', manager.prepareExplanations);
        manager.onTile('magnifyInEnd', manager.startShowingExplanations);
        manager.onTile('magnifyOutStart', manager.stopExplaining);
        manager.onTile('magnifyOutEnd', manager.removeExplanations);
    };

    return ExplanationManager;
});
