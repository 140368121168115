define('js/views/TileView',[
    'EventEmitter',
    '@brandwatch/date-ranges',
    '@vizia/resolve-props',
    'js/lib/windowMessaging',
    'js/lib/inherits',
    'js/lib/checkRequiredOptions',
    'js/lib/createElement',
    'js/config/clientSideConfig',
    'js/lib/analytics',
    'js/lib/screenTracking'
], function (
    EventEmitter,
    getDateRange,
    resolveProps,
    windowMessaging,
    inherits,
    checkRequiredOptions,
    createElement,
    clientSideConfig,
    analytics,
    screenTracking
) {
    'use strict';

    var loadingFadeTimeouts = new WeakMap();
    var loadingSlowTimeouts = new WeakMap();
    var tileData = new WeakMap();
    var tiles = new WeakMap();
    var config = clientSideConfig();

    function onIframeLoadFail(iframeEl, statusEl) {
        statusEl.textContent = 'Content failed to load';
        iframeEl.remove();
    }

    function onIframeLoadSlow(iframeEl, statusEl, extendedWaitTime) {
        statusEl.textContent = 'Content loading…';
        loadingSlowTimeouts.set(iframeEl, setTimeout(onIframeLoadFail, extendedWaitTime, iframeEl, statusEl));
    }

    function hideStatus(statusEl) {
        statusEl.style.display = 'none';
    }

    function buildQueryString(queries) {
        return Object.keys(queries).map(function(key) {
            return key + '=' + queries[key];
        }).join('&');
    }

    function TileView(options) {
        checkRequiredOptions('TileView', [
            'colors',
            'scene',
            'locale',
            'timezone'
        ], options);

        EventEmitter.call(this);

        this.colors = options.colors;
        this.index = options.index;
        this.scene = options.scene;
        this.locale = options.locale;
        this.themeId = options.themeId;
        this.theme = options.theme || null;
        this.timezone = options.timezone;
        this.token = options.token;
        this.installationId = options.installationId;
        this.initialWaitTime = options.initialWaitTime || 10000;
        this.extendedWaitTime = options.extendedWaitTime || 30000;
        this.nsfw = options.nsfw;
        this.el = createElement('div', 'TileView vl-flex-1');
        this.jwtRegex = /^[a-zA-Z0-9\-_=]+\.[a-zA-Z0-9\-_=]+\.[a-zA-Z0-9\-_=]+$/;
        this.vizia_access_token = options.vizia_access_token;
        this.bwApiRootUrl = options.bwApiRootUrl || config.bwApiRootUrl;
        this.environment = options.environment;
        this.imagecheckRootUrl = options.imagecheckRootUrl;
        this.flags = options.flags;
        this.is_public = options.is_public;
        this.reloadedOnDeckEvent = options.reloadedOnDeckEvent;

        this.onIframeLoad = this.onIframeLoad.bind(this);
    }

    inherits(TileView, EventEmitter);

    TileView.prototype.onIframeLoad = function onIframeLoad() {
        var iframeEl = this.el.querySelector('.bundle-loader-frame');
        var statusEl = this.el.querySelector('.TileView__status');

        clearTimeout(loadingSlowTimeouts.get(iframeEl));

        statusEl.classList.add('loaded');

        iframeEl.contentWindow.postMessage({config: tileData.get(iframeEl)}, config.bundleLoaderRootUrl);

        loadingFadeTimeouts.set(iframeEl, setTimeout(hideStatus, 500, statusEl));

        windowMessaging.register(iframeEl, tiles.get(iframeEl), tileData.get(iframeEl));
    };

    TileView.prototype.getQueries = function getQueries() {
        var queries = {
            bundle: encodeURIComponent(this.scene.type),
            cachebuster: this.index + '-' + Date.now().toString(36)
        };

        if (this.vizia_access_token && this.vizia_access_token.match(this.jwtRegex)) {
            queries.idToken = this.vizia_access_token;
        }

        return queries;
    };

    TileView.prototype.render = function render() {
        this.el.innerHTML = '';

        var resolvedProps = resolveProps({
            scene: this.scene,
            locale: this.locale,
            timezone: this.timezone
        });
        var dateRange = getDateRange(resolvedProps);
        var dateRangeLabel = dateRange.label || null;

        var bodyEl = createElement('div', 'TileView__body');
        var headerEl = createElement('div', 'TileView__header');
        var contentEl = createElement('div', 'TileView__content');
        var statusEl = createElement('div', 'TileView__status');

        var headerPrimaryEl = createElement('div', 'TileView__header__primary');
        var headerSecondaryEl = createElement('div', 'TileView__header__secondary');

        var iframeEl = createElement('iframe', 'bundle-loader-frame', {
            id: this.scene.id,
            src: config.bundleLoaderRootUrl + '?' + buildQueryString(this.getQueries())
        });

        this.el.appendChild(bodyEl);

        var show_header = typeof this.scene.show_header === 'undefined' ? true : this.scene.show_header;

        if (this.scene.name) {
            headerPrimaryEl.textContent = this.scene.name;
            headerEl.appendChild(headerPrimaryEl);
        }

        if (dateRangeLabel !== null) {
            headerSecondaryEl.textContent = dateRangeLabel;
            headerEl.appendChild(headerSecondaryEl);
        }

        if (show_header) {
            bodyEl.appendChild(headerEl);
        }

        bodyEl.appendChild(contentEl);
        bodyEl.appendChild(statusEl);

        contentEl.appendChild(iframeEl);

        loadingSlowTimeouts.set(iframeEl, setTimeout(
            onIframeLoadSlow,
            this.initialWaitTime,
            iframeEl,
            statusEl,
            this.extendedWaitTime
        ));

        tiles.set(iframeEl, this);

        tileData.set(iframeEl, {
            colors: this.colors,
            scene: this.scene,
            locale: this.locale,
            themeId: this.themeId,
            theme: this.theme,
            timezone: this.timezone,
            installationId: this.installationId,
            viewportUnits: {
                width: window.innerWidth,
                height: window.innerHeight
            },
            token: this.token,
            bwApiRootUrl: this.bwApiRootUrl,
            nsfw: this.nsfw,
            vizia_access_token: this.vizia_access_token,
            environment: this.environment,
            imagecheckRootUrl: this.imagecheckRootUrl,
            flags: this.flags,
            is_public: this.is_public,
            reloadedOnDeckEvent: this.reloadedOnDeckEvent
        });

        iframeEl.addEventListener('load', this.onIframeLoad);

        var event = {
            tabId: screenTracking.getId(),
            installationId: this.installationId,
            componentTypeId: this.scene.component_type_id,
            componentId: this.scene.id,
            slideId: this.scene.slide_id
        };

        if (!this.is_public) {
            analytics.promise().then(function (client) {
                client.track('bundle rendered', event);
            });
        }
    };

    TileView.prototype.remove = function remove() {
        var iframeEl = this.el.querySelector('.bundle-loader-frame');

        if (iframeEl) {
            windowMessaging.unregister(iframeEl);
            iframeEl.removeEventListener('load', this.onIframeLoad);
            clearTimeout(loadingFadeTimeouts.get(iframeEl));
            clearTimeout(loadingSlowTimeouts.get(iframeEl));
        }

        this.el.remove();
    };

    return TileView;
});
