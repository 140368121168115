define('js/lib/onceEvent',[],function () {
    'use strict';

    return function onceEvent(el, eventName) {
        return new Promise(function (resolve) {
            function once(evt) {
                if (evt.target !== el) {
                    return;
                }

                evt.stopPropagation();

                el.removeEventListener(eventName, once);

                resolve();
            }

            el.addEventListener(eventName, once);
        });
    };
});
