define('js/lib/reloadOnDeckEvents',[
    'js/lib/deckEvents',
    'js/lib/reloader'
], function (
    deckEvents,
    reloader
) {
    'use strict';

    var listeners;

    return {
        start: function () {
            listeners = deckEvents.eventsToListenFor.map(function (eventName) {
                return deckEvents.on(eventName, function () {
                    sessionStorage.setItem('reloadedOnDeckEvent', true);

                    reloader.reload();
                });
            });
        },
        stop: function () {
            listeners.forEach(function (listener) {
                deckEvents.off(listener);
            });
        }
    };
});
