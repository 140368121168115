define('js/config/healthCheck',[
    'js/lib/communicationErrors',
    'js/config/clientSideConfig'
], function (
    communicationErrors,
    clientSideConfig
) {
    'use strict';

    var config = clientSideConfig();

    function handleResponse(request, resolve, reject) {
        if (request.status < 200 || request.status > 299) {
            return reject(communicationErrors.createUnexpectedStatusError(request.status));
        }

        resolve();
    }

    function healthCheck() {
        var request = new XMLHttpRequest();
        request.timeout = 25 * 1000;

        return new Promise(function (resolve, reject) {
            request.onload = function () {
                handleResponse(request, resolve, reject);
            };

            request.onerror = function () {
                reject(communicationErrors.createRequestError());
            };

            request.ontimeout = function () {
                reject(communicationErrors.createTimeoutError());
            };

            request.open('GET', config.tychoRootUrl + 'v1/config/health-check');

            request.send();
        });
    }

    return healthCheck;
});
