define('js/lib/getConfigErrorMessage',[],function () {
    'use strict';

    function getConfigErrorMessage(error) {
        var code = error.code;
        switch (code) {
        case 403:
            return 'You do not have access to this Display.';
        case 404:
            return 'This Display has been deleted.';
        case 418:
            return 'I am a teapot.';
        default:
            return error.message || 'Retrieving config failed. Status Code: ' + code;
        }
    }

    return {
        get: getConfigErrorMessage
    };
});
