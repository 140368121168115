define('js/lib/requiredFeatures',[],function () {
    'use strict';

    return {
        promises: function () {
            return typeof window.Promise === 'function';
        },
        weakmaps: function () {
            return typeof window.WeakMap === 'function';
        }
    };
});
