define('js/lib/getUser',[
    'js/lib/auth',
    'js/globals/vizia_access_token',
    'js/lib/communicationErrors'
], function (
    auth,
    kioskAuth,
    communicationErrors
) {
    'use strict';

    var vizia_access_token = kioskAuth.get();
    var promise;

    function getPromise() {
        if (promise) {
            return promise;
        }

        try {
            var payload = JSON.parse(atob(vizia_access_token.split('.')[1]));
            promise = Promise.resolve({
                id: payload.sub,
                clientId: payload.clientId
            });
        } catch (err) {
            promise = auth.getStore()
                .then(function (store) {
                    return store.getToken({aud: 'vizia.brandwatch.com/foot-gun'});
                })
                .then(function (token) {
                    if (token === null) {
                        return new Promise(function (resolve, reject) {
                            reject(communicationErrors.createUnexpectedStatusError(401));
                        });
                    }
                    var payload = JSON.parse(atob(token.split('.')[1]));
                    return {
                        id: payload.sub,
                        clientId: payload.clientId
                    };
                });
        }

        return promise;
    }

    return {
        getPromise: getPromise,
        // Reset function is just for testing purposes.
        reset: function () {
            promise = null;
        }
    };
});
