define('telemetrySetup',[],function() {
    'use strict';

    window.telemetry = {};

    window.telemetry.getDomain = function () {
        var parts = window.location.hostname.split('.');
        if (!['vizia', 'vizia-stage'].includes(parts[0])) {
            parts.shift();
        }

        return parts.join('.');
    };

    try {
        // eslint-disable-next-line no-undef
        window.telemetry.instance = new ServiceTagger({
            serviceName: 'vizia',
            initConsole: true,
            loggerName: 'Bootstrap',
            sessionData: new Promise(function (resolve) {
                window.telemetry.sessionDetails = resolve;
            }),
            domain: window.telemetry.getDomain()
        });
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log('Telemetry not loaded...');
    }


    return window.telemetry;
});
