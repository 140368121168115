define('js/lib/TileMagnifier/steps/WaitStep',[
    'js/lib/checkRequiredOptions'
], function (
    checkRequiredOptions
) {
    'use strict';

    var requiredOptions = ['waitTime'];

    function WaitStep(options) {
        checkRequiredOptions('WaitStep', requiredOptions, options);
        this.waitTime = options.waitTime;
    }

    WaitStep.prototype.animate = function (component, done) {
        this.timeout = setTimeout(done, this.waitTime);
    };

    WaitStep.prototype.cleanup = function () {
        clearTimeout(this.timeout);
    };

    return WaitStep;
});
