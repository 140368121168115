define('js/lib/animationEndHelpers',[],function () {
    'use strict';

    var endEvents = [
        'animationend',
        'webkitAnimationEnd',
        'oAnimationEnd',
        'MSAnimationEnd',
        'transitionend',
        'webkitTransitionEnd',
        'oTransitionEnd',
        'MSTransitionEnd'
    ];

    return {
        addEndEventListeners: function (target, listener) {
            for (var i = 0, len = endEvents.length; i < len; i++) {
                target.addEventListener(endEvents[i], listener);
            }
        },

        removeEndEventListeners: function (target, listener) {
            for (var i = 0, len = endEvents.length; i < len; i++) {
                target.removeEventListener(endEvents[i], listener);
            }
        }
    };
});
