define('js/lib/TileMagnifier/TileMagnifier',[
    'js/lib/checkRequiredOptions',
    'js/lib/TileMagnifier/animateStepsInSequence',
    'js/lib/TileMagnifier/steps/EmphasizeStep',
    'js/lib/TileMagnifier/steps/MagnifyInStep',
    'js/lib/TileMagnifier/steps/WaitStep',
    'js/lib/TileMagnifier/steps/MagnifyOutStep'
], function (
    checkRequiredOptions,
    steps,
    EmphasizeStep,
    MagnifyInStep,
    WaitStep,
    MagnifyOutStep
) {
    'use strict';

    var requiredOptions = [
        'sceneEl',
        'timeBetweenMagnifications',
        'magnificationHoldTime'
    ];

    function TileMagnifier(options) {
        checkRequiredOptions('TileMagnifier', requiredOptions, options);

        this.sceneEl = options.sceneEl;
        this.timeBetweenMagnifications = options.timeBetweenMagnifications;

        this.steps = [
            new WaitStep({waitTime: options.timeBetweenMagnifications}),
            new EmphasizeStep({sceneEl: this.sceneEl}),
            new MagnifyInStep({sceneEl: this.sceneEl}),
            new WaitStep({waitTime: options.magnificationHoldTime}),
            new MagnifyOutStep({sceneEl: this.sceneEl})
        ];
    }

    TileMagnifier.prototype.magnifyTile = function (tile) {
        return steps.animateInSequence(this.steps, tile);
    };

    TileMagnifier.prototype.stop = function () {
        this.steps.forEach(function (step) {
            step.cleanup();
        });
        clearTimeout(this.timeout);
    };

    return TileMagnifier;
});
