define('js/lib/retryer',[],function () {
    'use strict';

    var defaults = {
        min: 1000 * 5,
        max: 1000 * 60 * 10,
        step: function doubler(min, i) {
            return min * Math.pow(2, i);
        }
    };

    function retryer(func, conf) {
        var userConf = conf || {};
        var timeout;
        var i = 0;
        var min =  userConf.min || defaults.min;
        var max = userConf.max || defaults.max;
        var step = userConf.step || defaults.step;

        function calculateTimeout() {
            return Math.min(step(min, i), max);
        }

        var self = {
            retry: function retry() {
                timeout = setTimeout(wrapped, calculateTimeout());
                i++;
            },
            reset: function reset() {
                clearTimeout(timeout);
                i = 0;
            }
        };

        function wrapped() {
            return func(self.retry, self.reset);
        }

        wrapped();

        return self;
    }

    retryer.wrap = retryer;

    return retryer;
});
