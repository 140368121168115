define('js/lib/queryString',[
    'vendor/wrapple'
], function (
    wrapple
) {
    'use strict';

    wrapple('location');

    function parseQueryString() {
        var searchString = wrapple.location().search.substring(1);
        var params = {};

        if (searchString.length === 0) {
            return params;
        }

        searchString.split('&').forEach(function (keyValueString) {
            var keyValuePair = keyValueString.split('=');
            params[keyValuePair[0]] = keyValuePair[1];
        });

        return params;
    }

    return {parse: parseQueryString};
});
