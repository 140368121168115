define('js/lib/escapes',[],function () {
    'use strict';

    function htmlEscape(text) {
        return text
            .replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;');
    }

    function attributeEscape(text) {
        return htmlEscape(text)
            .replace(/"/g, '&quot;')
            .replace(/'/g, '&#39;');
    }

    return {
        html: htmlEscape,
        attribute: attributeEscape
    };
});
