define('js/controllers/messages',[
    'js/lib/viziaEvents',
    'js/lib/queryString',
    'js/config/clientSideConfig',
    'js/views/TickerMessageView',
    'js/views/PopupMessageView',
    'js/lib/MessageQueue',
    'js/lib/retryer',
    'js/globals/displayId',
    'js/globals/isPreview'
], function (
    viziaEvents,
    queryString,
    clientSideConfig,
    TickerMessageView,
    PopupMessageView,
    MessageQueue,
    retryer,
    displayId,
    isPreview
) {
    'use strict';

    var notificationTypeToConstructor = {
        popup: PopupMessageView,
        ticker: TickerMessageView
    };

    var messageView = null;

    function addMessageView(message) {
        var MessageViewConstructor = notificationTypeToConstructor[message.notificationType];

        messageView = new MessageViewConstructor(message);

        document.body.appendChild(messageView.el);

        messageView.animate();
    }

    function removeMessageView() {
        if (!messageView) {
            return Promise.resolve();
        }

        return messageView.remove().then(function () {
            messageView = null;
        });
    }

    function updateView(message) {
        return removeMessageView().then(function () {
            if (message) {
                addMessageView(message);
            }
        });
    }

    function checkMessage(message) {
        if (!message || !Array.isArray(message.displayIds)) {
            return false;
        }

        if (message.displayIds.indexOf(displayId) === -1) {
            return false;
        }

        return Object.prototype.hasOwnProperty.call(notificationTypeToConstructor, message.notificationType);
    }

    var messageQueue = new MessageQueue({onChange: updateView});

    function init(opts) {
        if (isPreview) {
            return;
        }

        var config = clientSideConfig();
        var messagesUrl = [
            config.eventsRootUrl,
            'v1/messages/installation/',
            opts.installationId,
            '?vizia_access_token=',
            encodeURIComponent(opts.vizia_access_token)
        ].join('');

        retryer(function (retry, reset) {
            var request = new XMLHttpRequest();

            request.onload = function () {
                var status = request.status;

                if (status >= 400 || status < 200) {
                    return retry();
                }

                reset();

                var messages = JSON.parse(request.responseText).filter(checkMessage);

                messageQueue = new MessageQueue({onChange: updateView});
                messageQueue.add.apply(messageQueue, messages);

                viziaEvents.on('viziaEvents:error', function () {
                    retry();
                }, 1);
            };

            request.onerror = retry;

            request.timeout = config.requestTimeout * 1000;
            request.withCredentials = true;
            request.open('GET', messagesUrl);
            request.send();
        });
    }

    viziaEvents.on('message:send', function (message) {
        if (checkMessage(message)) {
            messageQueue.add(message);
        }
    });

    viziaEvents.on('message:remove', function (message) {
        if (checkMessage(message)) {
            messageQueue.remove(message);
        }
    });

    return {init: init};
});
