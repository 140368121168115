define('js/lib/messageHelpers',[
    'js/lib/escapes'
], function (
    escapes
) {
    'use strict';

    var iconClasses = new Map([
        ['information', 'icon-info'],
        ['warning', 'icon-warning'],
        ['success', 'icon-success']
    ]);

    function makeTemplateData(spec) {
        var messageStyle = escapes.attribute(spec.style || 'default');

        return {
            title: spec.title,
            content: spec.content,
            styleClass: 'vc-message-' + escapes.attribute(spec.notificationType) + '-' + messageStyle,
            iconClass: iconClasses.get(messageStyle)
        };
    }

    return {
        makeTemplateData: makeTemplateData
    };
});
