define('js/lib/MessageQueue',[],function () {
    'use strict';

    function addMessageToQueue(messageQueue, message) {
        var duration = typeof message.remainingTime === 'number' ? message.remainingTime : message.duration;

        messageQueue.queue.push(message);

        message.timeout = setTimeout(function () {
            messageQueue.remove(message);
        }, duration);
    }

    function removeMessageFromQueue(messageQueue, message) {
        var queue = messageQueue.queue;

        for (var i = 0, len = queue.length; i < len; i++) {
            if (queue[i].uuid === message.uuid) {
                clearTimeout(queue[i].timeout);
                queue.splice(i, 1);
                return;
            }
        }
    }

    function MessageQueue(options) {
        if (!options || !options.onChange) {
            throw new Error('MessageQueue instances must be constructed with an onChange option.');
        }

        this.queue = [];
        this.onChange = options.onChange;
    }

    MessageQueue.prototype.add = function () {
        var queue = this.queue;
        var oldMessage = queue[0];

        outer: for (var i = 0, ilen = arguments.length; i < ilen; i++) {
            for (var j = 0, jlen = queue.length; j < jlen; j++) {
                if (arguments[i].uuid === queue[j].uuid) {
                    continue outer;
                }
            }

            addMessageToQueue(this, arguments[i]);
        }

        queue.sort(function publishedDescend(a, b) {
            return a.publishTime < b.publishTime;
        });

        var newMessage = queue[0];

        if (newMessage !== oldMessage) {
            this.onChange(newMessage);
        }
    };

    MessageQueue.prototype.remove = function () {
        var queue = this.queue;
        var oldMessage = queue[0];

        for (var i = 0, len = arguments.length; i < len; i++) {
            removeMessageFromQueue(this, arguments[i]);
        }

        var newMessage = queue[0];

        if (newMessage !== oldMessage) {
            this.onChange(newMessage);
        }
    };

    return MessageQueue;
});
