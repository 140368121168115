define('js/lib/checkRequiredFeatures',[
    'js/lib/requiredFeatures'
], function (
    featureTestMap
) {
    'use strict';

    return function checkRequiredFeatures() {
        var requiredFeatures = Object.keys(featureTestMap);
        var unsupportedFeatures = [];

        requiredFeatures.forEach(function (featureName) {
            var isSupported = featureTestMap[featureName];

            try {
                if (!isSupported()) {
                    unsupportedFeatures.push(featureName);
                }
            } catch (error) {
                unsupportedFeatures.push(featureName);
            }
        });

        return unsupportedFeatures;
    };
});
