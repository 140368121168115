define('requireConfig',[],function () {
    'use strict';

    return require.config({
        waitSeconds: typeof self === 'undefined' ? 7 : self.CONFIG.requestTimeout,
        paths: {
            requireLib: 'vendor/require',
            text: 'vendor/text',
            json: 'js/plugins/json',
            jed: 'vendor/jed',
            'donny-auth': 'vendor/donny-auth',
            EventEmitter: './vendor/vertebrate-event-emitter',
            'ldclient-js': 'vendor/ldclient-js/ldclient',
            moment: 'vendor/moment',
            alloyfinger: 'vendor/alloy_finger',
            Rollbar: 'vendor/rollbar/rollbar.umd',
            mixpanel: 'vendor/mixpanel',
            'moment/en-gb': 'vendor/moment/en-gb',
            'moment/it': 'vendor/moment/it',
            'moment/fr': 'vendor/moment/fr',
            'moment/de': 'vendor/moment/de',
            'moment/es': 'vendor/moment/es',
            'moment/pt-br': 'vendor/moment/pt-br',
            luxon: 'vendor/luxon',
            '@brandwatch/date-ranges': 'vendor/@brandwatch/date-ranges',
            '@vizia/design-tokens': 'vendor/@vizia/design-tokens/index',
            '@vizia/resolve-props': 'vendor/@vizia/resolve-props/index',
            'dom-snow': 'vendor/dom-snow'
        }
    });
});
