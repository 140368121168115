define('js/views/CountdownMessageOverlayView',[
    'js/lib/createElement'
], function (
    createElement
) {
    'use strict';

    var messageEls = new WeakMap();
    var timerEls = new WeakMap();
    var animationRequestIds = new WeakMap();

    function CountdownMessageOverlayView() {
        this.el = createElement('div', 'CountdownMessageOverlayView');

        var messageEl = createElement('div', 'CountdownMessageOverlayView__message');
        this.el.appendChild(messageEl);
        messageEls.set(this, messageEl);

        var timerEl = createElement('div', 'CountdownMessageOverlayView__timer');
        this.el.appendChild(timerEl);
        timerEls.set(this, timerEl);
    }

    CountdownMessageOverlayView.prototype.render = function (message, duration) {
        var displayTime = duration;
        var self = this;
        var t0 = performance.now();

        if (animationRequestIds.has(this)) {
            cancelAnimationFrame(animationRequestIds.get(this));
            animationRequestIds.delete(this);
        }

        messageEls.get(this).textContent = message;
        timerEls.get(this).textContent = 'Trying again in ' + duration + 's';

        return new Promise(function (resolve) {
            (function renderLoop(currentTime) {
                var elapsedTime = (currentTime - t0) / 1000;

                if (elapsedTime >= duration) {
                    resolve();
                    return;
                }

                animationRequestIds.set(self, requestAnimationFrame(renderLoop));

                var newDisplayTime = Math.ceil(duration - elapsedTime);

                if (newDisplayTime === displayTime) {
                    return;
                }

                displayTime = newDisplayTime;

                timerEls.get(self).textContent = 'Trying again in ' + displayTime + 's';
            }(t0));
        });
    };

    CountdownMessageOverlayView.prototype.remove = function () {
        cancelAnimationFrame(animationRequestIds.get(this));
        animationRequestIds.delete(this);
        this.el.remove();
    };

    return CountdownMessageOverlayView;
});
