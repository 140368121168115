define('js/views/SharedDeckView',[
    'js/config/clientSideConfig',
    'js/lib/createElement'
], function (
    clientSideConfig,
    createElement
) {
    'use strict';

    function SharedDeckView(opts) {
        this.el = document.createElement('div');
        this.el.className = 'SharedDeckView';

        this.options = Object.assign({
            deckId: null,
            deckName: null,
            folderId: null
        }, opts);
    }

    SharedDeckView.prototype.render = function() {
        var linkContainer = createElement('div', 'vl-flex-1 deck-link');
        var adminDeckLink = document.createElement('a');
        var deckUrl = new URL(
            '/folders/' + this.options.folderId + '/decks/' + this.options.deckId + '/slides',
            clientSideConfig().admin3RootUrl
        );

        adminDeckLink.className = 'vc-text';
        adminDeckLink.setAttribute('href', deckUrl.href);
        adminDeckLink.setAttribute('title', 'Open Deck "' + this.options.deckName + '"');

        adminDeckLink.innerText = this.options.deckName;

        linkContainer.appendChild(adminDeckLink);
        this.el.appendChild(linkContainer);
    };

    return SharedDeckView;
});
