define('js/config/clientSideConfig',[],function () {
    'use strict';

    var config = {};

    Object.keys(window.CONFIG).forEach(function (key) {
        var spec = window.CONFIG[key];

        config[key] = spec.type === 'url' ? new URL(spec.value).href : spec.value;
    });

    return function getConfig() {
        return config;
    };
});
