define('js/views/SceneHeaderView',[
    'js/lib/checkRequiredOptions',
    'js/lib/createElement',
    'js/views/SceneTitleView',
    'js/views/SceneLogoView',
    'js/views/ClockView',
    'js/lib/featureFlags'
], function (
    checkRequiredOptions,
    createElement,
    SceneTitleView,
    SceneLogoView,
    ClockView,
    featureFlags
) {
    'use strict';

    var requiredOptions = [
        'title',
        'logoSrc',
        'locale',
        'timezone'
    ];

    function hideSceneAndClockPromise() {
        return featureFlags.promise().then(function (ldclient) {
            return ldclient.safeVariation('hide-scene-title-and-clock', false);
        });
    }

    function SceneHeaderView(options) {
        checkRequiredOptions('SceneHeaderView', requiredOptions, options);

        this.titleView = new SceneTitleView({
            title: options.title
        });
        this.clockView = new ClockView({
            locale: options.locale,
            timezone: options.timezone
        });
        this.logoView = new SceneLogoView({
            logoSrc: options.logoSrc,
            isLegacyTheme: !options.theme
        });

        this.el = createElement('div', 'sceneHeader');
        this.headerEl = createElement('div', 'sceneHeader__header');

        this.el.appendChild(this.headerEl);
        this.el.appendChild(this.clockView.el);
        this.headerEl.appendChild(this.logoView.el);
        this.headerEl.appendChild(this.titleView.el);
    }

    SceneHeaderView.prototype.render = function render() {
        this.logoView.render();

        return hideSceneAndClockPromise().then(function(shouldHide) {
            if (shouldHide) {
                console.warn('hiding title view and clock due to LD flag'); //eslint-disable-line no-console
                return;
            }

            this.titleView.render();
            this.clockView.render();
        }.bind(this));
    };

    SceneHeaderView.prototype.remove = function remove() {
        this.titleView.remove();
        this.logoView.remove();
        this.clockView.remove();
        this.el.remove();
        this.headerEl.remove();
    };

    return SceneHeaderView;
});
