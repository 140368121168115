define('js/views/SceneControlsView',[
    'EventEmitter',
    'js/lib/inherits',
    'js/lib/createElement'
], function (
    EventEmitter,
    inherits,
    createElement
) {
    'use strict';

    var instances = new WeakMap();
    var unbindEventFns = new WeakMap();
    var fadeTimeouts = new WeakMap();

    var SPACEBAR = 32;
    var LEFT_ARROW = 37;
    var RIGHT_ARROW = 39;

    function onButtonPress(event) {
        var targetEl = event.target.tagName === 'svg' ? event.target : event.target.parentElement;
        var view = instances.get(targetEl.parentElement);

        if (!targetEl.classList.contains('SceneControlsView__button')) {
            return;
        }
        if (targetEl.classList.contains('SceneControlsView__button--previous')) {
            return view.trigger('previous');
        }
        if (targetEl.classList.contains('SceneControlsView__button--pause')) {
            view.el.classList.add('SceneControlsView--paused');
            return view.trigger('pause');
        }
        if (targetEl.classList.contains('SceneControlsView__button--play')) {
            view.el.classList.remove('SceneControlsView--paused');
            return view.trigger('play');
        }
        if (targetEl.classList.contains('SceneControlsView__button--next')) {
            return view.trigger('next');
        }
    }

    function hideView(view) {
        view.el.classList.add('SceneControlsView--hide');
    }

    function showView(view) {
        clearTimeout(fadeTimeouts.get(view));
        view.el.classList.remove('SceneControlsView--hide');
        fadeTimeouts.set(view, setTimeout(hideView, 4000, view));
    }

    function handleKeyPress(view, keyCode) {
        if (keyCode === LEFT_ARROW) {
            showView(view);
            view.trigger('previous');
        } else if (keyCode === RIGHT_ARROW) {
            showView(view);
            view.trigger('next');
        } else if (keyCode === SPACEBAR) {
            showView(view);

            if (view.el.classList.toggle('SceneControlsView--paused')) {
                view.trigger('pause');
            } else {
                view.trigger('play');
            }
        }
    }

    function SceneControlsView() {
        EventEmitter.call(this);

        this.el = createElement('div', 'SceneControlsView');
        instances.set(this.el, this);
        this.el.addEventListener('click', onButtonPress);
        unbindEventFns.set(this, new Set());

        hideView(this);
    }

    inherits(SceneControlsView, EventEmitter);

    SceneControlsView.prototype.render = function render() {
        /* eslint-disable max-len */
        this.el.innerHTML = '\
        <svg viewBox="0 0 20 20" class="SceneControlsView__button SceneControlsView__button--previous" data-viz-at="button-previous"><polygon points="20,15 10,15 10,20 0,10 10,0 10,5 20,5"/></svg>\
        <svg viewBox="0 0 20 20" class="SceneControlsView__button SceneControlsView__button--play" data-viz-at="button-play"><polygon points="4,0 20,10 4,20"/></svg>\
        <svg viewBox="0 0 20 20" class="SceneControlsView__button SceneControlsView__button--pause" data-viz-at="button-pause"><rect x="3" width="5" height="20"/><rect x="12" width="5" height="20"/></svg>\
        <svg viewBox="0 0 20 20" class="SceneControlsView__button SceneControlsView__button--next" data-viz-at="button-next"><polygon points="0,15 10,15 10,20 20,10 10,0 10,5 0,5"/></svg>';
        /* eslint-enable max-len */
    };

    SceneControlsView.prototype.listenTo = function listenTo(targetEl) {
        var view = this;

        function onMouseMove() {
            showView(view);
        }
        function onKeyUp(event) {
            handleKeyPress(view, event.keyCode);
        }

        targetEl.addEventListener('mousemove', onMouseMove);
        targetEl.addEventListener('keyup', onKeyUp);

        unbindEventFns.get(this).add(function () {
            targetEl.removeEventListener('mousemove', onMouseMove);
        });
        unbindEventFns.get(this).add(function () {
            targetEl.removeEventListener('keyup', onKeyUp);
        });
    };

    SceneControlsView.prototype.remove = function remove() {
        clearTimeout(fadeTimeouts.get(this));
        this.el.removeEventListener('click', onButtonPress);

        unbindEventFns.get(this).forEach(function (unbindEventListener) {
            unbindEventListener();
        });

        this.el.remove();
    };

    return SceneControlsView;
});
