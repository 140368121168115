define('js/lib/checkRequiredOptions',[],function () {
    'use strict';

    return function checkRequiredOptions(moduleName, requiredOptions, options) {
        if (!options || typeof options !== 'object') {
            throw new Error(moduleName + ' requires an options hash');
        }

        requiredOptions.forEach(function checkOption(option) {
            if (options[option] === undefined) {
                throw new Error(moduleName + ' requires ' + option);
            }
        });
    };
});
