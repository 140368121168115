define('js/lib/loadTheme',[
    '@vizia/design-tokens',
    'js/config/clientSideConfig',
    'js/lib/injectCSS',
    'dom-snow'
], function (
    getDesignTokens,
    clientSideConfig,
    injectCssFromUrl,
    Snow
) {
    'use strict';

    function injectCssFromTokens(cssTokens) {
        Object.entries(cssTokens).forEach(function(entry) {
            document.documentElement.style.setProperty(entry[0], entry[1]);
        });
    }

    function handleTheme(config) {
        var theme = config.options.theme;
        var designTokens = getDesignTokens(theme);

        injectCssFromTokens(designTokens.css);
        if (theme.effects) {
            handleEffects(theme.effects);
        }
    }

    function handleEffects(effects) {
        effects.forEach(function(effect) {
            switch (effect.type) {
            case 'snow':
                var snow = new Snow({
                    el: document.body,
                    generationSpeed: Math.floor(((effect.speed || 30) / 2000) * window.innerWidth),
                    flakeSize: function() {
                        return Math.random() * (effect.size || 3);
                    },
                    staticOnceLanded: true,
                    flakeCompaction: effect.compaction || 0.9,
                    flakesLand: effect.land || 0.9,
                    flakeLife: 20000
                });
                window.onSlideChange = function () {
                    snow.setCollidableDomElements(document.querySelectorAll('.TileView__body'));
                    snow.clear(true);
                };
                window.addEventListener('resize', function() {
                    snow.generationSpeed = Math.floor(((effect.speed || 30) / 2000) * window.innerWidth);
                    snow.resize();
                    snow.setCollidableDomElements(document.querySelectorAll('.TileView__body'));
                });
                snow.start();
                break;
            default:
                // eslint-disable-next-line no-console
                console.error('no known effect:', effect.type);
            }
        });
    }

    function handleLegacyTheme(config) {
        var themeId = config.options.themeId;
        injectCssFromUrl(clientSideConfig().themesRootUrl + themeId + '/theme.next.css');
    }

    return function loadTheme(config) {
        var theme = config && config.options && config.options.theme;
        var themeId = config && config.options && config.options.themeId;

        if (theme) {
            handleTheme(config);
        } else if (themeId) {
            handleLegacyTheme(config);
        }
    };
});
