define('js/lib/touchEvents',[
    'js/lib/createElement',
    'js/lib/sceneRotator',
    'alloyfinger'
], function (
    createElement,
    sceneRotator
) {
    'use strict';

    return {
        addTouchSupport: function () {
            var el = createElement('div', 'touchOverlay');
            document.body.appendChild(el);

            el.addEventListener('mousemove', function () {
                console.log('touch events disabled', el); //eslint-disable-line no-console
                document.body.removeChild(el);
            });

            var af = new window.AlloyFinger(el, {
                swipe: function (evt) {
                    if (evt.direction === 'Left') {
                        sceneRotator.previous();
                    } else if (evt.direction === 'Right') {
                        sceneRotator.next();
                    }
                }
            });

            console.log('touch events enabled', el, af); //eslint-disable-line no-console
        }
    };
});
